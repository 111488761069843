import React, { useState } from "react";
import "./Withdraw.css";
// import "../../../src/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaFileUpload } from "react-icons/fa";
import Header from "../header/Header";

const Withdraw = () => {
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("new-account"); // Set default to 'new-account'
  const [isSubmitted, setIsSubmitted] = useState(false); // State for form submission
  const [buttonText, setButtonText] = useState("Submit"); // State for button text
  const [showPaymentMethods, setPaymentMethods] = useState(false);
  const [showAddAccountForm, setAddAccountForm] = useState(false);
  const [showBankForm, setShowBankForm] = useState(false);
  const [showGpayForm, setShowGpayForm] = useState(false);
  const [showPhonepeForm, setShowPhonepeForm] = useState(false);
  const [showPaytmForm, setShowPaytmForm] = useState(false);
  const [showUpiForm, setShowUpiForm] = useState(false);
  const [showWalletForm, setShowWalletForm] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false); // State to handle Edit form popup visibility
  const [editingSection, setEditingSection] = useState(null); // Track which section is being edited
  const [showPreviousAccount, setShowPreviousAccount] = useState(false);

  const [bankDetails, setBankDetails] = useState({
    name: "Example",
    accountNumber: "12345678xxxx",
    ifscCode: "SBIN000002",
    bankName: "State Bank of India",
  });

  const [UPIDetails, setUPIDetails] = useState({
    upi_id: "1234fgd",
    phoneNumber: "12345678xxxx",
  });

  // Handle changes in the Bank input fields during editing
  const handleBankInputChange = (e) => {
    const { name, value } = e.target;
    setBankDetails({
      ...bankDetails,
      [name]: value,
    });
  };

  // Handle changes in the UPI input fields during editing
  const handleUPIInputChange = (e) => {
    const { name, value } = e.target;
    setUPIDetails({
      ...UPIDetails,
      [name]: value,
    });
  };

  const toggleEditPopup = (section) => {
    setEditingSection(section); // Set the section (either "bank" or "upi")
    setShowEditPopup(!showEditPopup); // Toggle the popup
  };

  const handlePreviousAccount = () => {
    setShowPreviousAccount(!showPreviousAccount);
    setPaymentMethods(false)
  }

  // Function to handle button click
  const handleButtonClick = (value) => {
    setInputValue(value);
  };

  // Function to handle payment option click
  const handleClick = (option) => {
    setSelectedOption(selectedOption === option ? null : option); // Toggle between opening/closing
  };

  const handlePaymentMethod = () => {
    setPaymentMethods(!showPaymentMethods);
    // Hide all forms using an array of form setters
    [
      setShowBankForm,
      setShowGpayForm,
      setShowPhonepeForm,
      setShowPaytmForm,
      setShowUpiForm,
      setShowWalletForm,
    ].forEach((setter) => setter(false));
  };

  const handleFormToggle = (form) => {
    setShowBankForm(form === "bank");
    setShowGpayForm(form === "gpay");
    setShowPhonepeForm(form === "phonepe");
    setShowPaytmForm(form === "paytm");
    setShowUpiForm(form === "upi");
    setShowWalletForm(form === "wallet");
  };

  // Button values
  const buttonValues = [500, 1000, 1500, 2000, 2500, 3000];

  const tickMark = (
    <svg
      width="58"
      height="45"
      viewBox="0 0 58 45"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#fff"
        fillRule="nonzero"
        d="M19.11 44.64L.27 25.81l5.66-5.66 13.18 13.18L52.07.38l5.65 5.65"
      />
    </svg>
  );

  // Handle submit click and toggle button text & style
  const handleSubmitClick = () => {
    if (isSubmitted) {
      setButtonText("Submit"); // Reset the button text to "Submit"
      setIsSubmitted(false); // Remove the active class
    } else {
      setButtonText("Thanks"); // Change the text to "Thanks"
      setIsSubmitted(true); // Set the button to active
    }
  };

  return (
    <>
      <Header />
      <div className="deposit-withdraw">
        <p className="deposit">
          <span>Withdraw </span>
        </p>

        <div className="withdraw-payments-symbol">
          <h3 className="payments-options">Withdraw Funds</h3>
          <p className="see-more">
            This form is for withdrawing the amount from the main wallet only.
          </p>
          <ul className="withdraw-payments">
            <li
              className="click-account"
              onClick={() => handleClick("new-account")}
            >
              <button onClick={handlePaymentMethod}>Use New Account</button>
            </li>

            <li
              className="click-account"
              onClick={() => handleClick("previous-account")}
            >
              <button onClick={handlePreviousAccount}>Use Previous Account</button>
            </li>
          </ul>
        </div>

        {showPaymentMethods && (
          <div className="payment-methods">
            <ul className="payment-types">
              <button
                className="payment-btn"
                onClick={() => handleFormToggle("bank")}
              >
                <li className="payment-apps">
                  <img src="../Images/bank.png" alt="Bank" />
                </li>
              </button>
              <button
                className="payment-btn"
                onClick={() => handleFormToggle("gpay")}
              >
                <li className="payment-apps">
                  <img src="../Images/G-pay.png" alt="GPay" />
                </li>
              </button>
              <button
                className="payment-btn"
                onClick={() => handleFormToggle("phonepe")}
              >
                <li className="payment-apps">
                  <img src="../Images/phonepe.png" alt="PhonePe" />
                </li>
              </button>
              <button
                className="payment-btn"
                onClick={() => handleFormToggle("paytm")}
              >
                <li className="payment-apps">
                  <img src="../Images/paytm.png" alt="Paytm" />
                </li>
              </button>
              <button
                className="payment-btn"
                onClick={() => handleFormToggle("upi")}
              >
                <li className="payment-apps">
                  <img src="../Images/UPI-Color.png" alt="UPI" />
                </li>
              </button>
              <button
                className="payment-btn"
                onClick={() => handleFormToggle("wallet")}
              >
                <li className="payment-apps">
                  <img
                    src="../Images/wallet.png"
                    alt="Wallet"
                    className="wallet-image"
                  />
                </li>
              </button>
            </ul>
          </div>
        )}

        <div className="payment-details">
          {showBankForm && (
            <div className="details">
              <form action="#">
                <h4>Available to Withdrawl: &#8377;0</h4>
                <div className="methods-heading">
                  <p>Submit Your Account Details</p>
                </div>
                <div className="withdraw-form-input">
                  <p>
                    <input
                      type="number"
                      placeholder=" &#8377;  &nbsp; Enter Amount"
                    />
                  </p>
                </div>
                <div className="withdraw-form-input">
                  {/* <p>IFSC Code<span className='red'>*</span></p> */}
                  <p>
                    <input type="text" placeholder=" Enter IFSC Code" />
                  </p>
                </div>
                <div className="withdraw-form-input">
                  {/* <p>Account No<span className='red'>*</span></p> */}
                  <p>
                    <input type="number" placeholder=" Enter Account Number" />
                  </p>
                </div>
                <div className="withdraw-form-input">
                  {/* <p>Confirm Account No<span className='red'>*</span></p> */}
                  <p>
                    <input
                      type="number"
                      placeholder=" Re-enter Account Number"
                    />
                  </p>
                </div>
                <div className="withdraw-form-input">
                  {/* <p>Account Name<span className='red'>*</span></p> */}
                  <p>
                    <input type="text" placeholder=" Enter Account Name" />
                  </p>
                </div>
                <div className="otp-div withdraw-form-input">
                  <p className="otp">Click on get OTP to verify your account</p>
                  <p>
                    <input type="number" placeholder=" +91 999000999" />
                    <button className="button-container">Get OTP</button>
                  </p>
                </div>
                <div className="agree">
                  <p>
                    <input type="checkbox" />{" "}
                    <span className="terms-conditions">
                      I have read the term and condition
                    </span>{" "}
                    <a className="terms-conditions-link" href="#">
                      the terms of payments and withdrawl policy
                    </a>
                  </p>
                </div>
                <div className="section-submit-button">
                  <button
                    id="btn"
                    className={`payment-submit-button ${
                      isSubmitted ? "active" : ""
                    }`}
                    onClick={handleSubmitClick}
                  >
                    <p className="submit-button-text">{buttonText}</p>
                    <div className="checked">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                      >
                        <path
                          fill="transparent"
                          d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>

        <div className="payment-details">
          {showGpayForm && (
            <div className="details">
              <form action="#">
                <h4>Available to Withdrawl: &#8377;0</h4>
                <div className="methods-heading">
                  <p>Submit Your G-pay Details</p>
                </div>
                <div className="withdraw-form-input">
                  <p>
                    <input
                      type="number"
                      placeholder=" &#8377;  &nbsp; Enter Amount"
                    />
                  </p>
                </div>
                <div className="withdraw-form-input">
                  {/* <p>IFSC Code<span className='red'>*</span></p> */}
                  <p>
                    <input type="text" placeholder=" Enter Mobile Number" />
                  </p>
                </div>
                <div className="withdraw-form-input">
                  {/* <p>Account Name<span className='red'>*</span></p> */}
                  <p>
                    <input type="text" placeholder=" Enter Account Name" />
                  </p>
                </div>
                <div className="otp-div withdraw-form-input">
                  <p className="otp">Click on get OTP to verify your account</p>
                  <p>
                    <input type="number" placeholder=" +91 999000999" />
                    <button className="button-container">Get OTP</button>
                  </p>
                </div>
                <div className="agree">
                  <p>
                    <input type="checkbox" />{" "}
                    <span className="terms-conditions">
                      I have read the term and condition
                    </span>{" "}
                    <a className="terms-conditions-link" href="#">
                      the terms of payments and withdrawl policy
                    </a>
                  </p>
                </div>
                <div className="section-submit-button">
                  <button
                    id="btn"
                    className={`payment-submit-button ${
                      isSubmitted ? "active" : ""
                    }`}
                    onClick={handleSubmitClick}
                  >
                    <p className="submit-button-text">{buttonText}</p>
                    <div className="checked">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                      >
                        <path
                          fill="transparent"
                          d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>

        <div className="payment-details">
          {showPhonepeForm && (
            <div className="details">
              <form action="#">
                <h4>Available to Withdrawl: &#8377;0</h4>
                <div className="methods-heading">
                  <p>Submit Your PhonePe Details</p>
                </div>
                <div className="withdraw-form-input">
                  <p>
                    <input
                      type="number"
                      placeholder=" &#8377;  &nbsp; Enter Amount"
                    />
                  </p>
                </div>
                <div className="withdraw-form-input">
                  {/* <p>IFSC Code<span className='red'>*</span></p> */}
                  <p>
                    <input type="text" placeholder=" Enter Mobile Number" />
                  </p>
                </div>
                <div className="withdraw-form-input">
                  {/* <p>Account Name<span className='red'>*</span></p> */}
                  <p>
                    <input type="text" placeholder=" Enter Account Name" />
                  </p>
                </div>
                <div className="otp-div withdraw-form-input">
                  <p className="otp">Click on get OTP to verify your account</p>
                  <p>
                    <input type="number" placeholder=" +91 999000999" />
                    <button className="button-container">Get OTP</button>
                  </p>
                </div>
                <div className="agree">
                  <p>
                    <input type="checkbox" />{" "}
                    <span className="terms-conditions">
                      I have read the term and condition
                    </span>{" "}
                    <a className="terms-conditions-link" href="#">
                      the terms of payments and withdrawl policy
                    </a>
                  </p>
                </div>
                <div className="section-submit-button">
                  <button
                    id="btn"
                    className={`payment-submit-button ${
                      isSubmitted ? "active" : ""
                    }`}
                    onClick={handleSubmitClick}
                  >
                    <p className="submit-button-text">{buttonText}</p>
                    <div className="checked">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                      >
                        <path
                          fill="transparent"
                          d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>

        <div className="payment-details">
          {showPaytmForm && (
            <div className="details">
              <form action="#">
                <h4>Available to Withdrawl: &#8377;0</h4>
                <div className="methods-heading">
                  <p>Submit Your Paytm Details</p>
                </div>
                <div className="withdraw-form-input">
                  <p>
                    <input
                      type="number"
                      placeholder=" &#8377;  &nbsp; Enter Amount"
                    />
                  </p>
                </div>
                <div className="withdraw-form-input">
                  {/* <p>IFSC Code<span className='red'>*</span></p> */}
                  <p>
                    <input type="text" placeholder=" Enter Mobile Number" />
                  </p>
                </div>
                <div className="withdraw-form-input">
                  {/* <p>Account Name<span className='red'>*</span></p> */}
                  <p>
                    <input type="text" placeholder=" Enter Account Name" />
                  </p>
                </div>
                <div className="otp-div withdraw-form-input">
                  <p className="otp">Click on get OTP to verify your account</p>
                  <p>
                    <input type="number" placeholder=" +91 999000999" />
                    <button className="button-container">Get OTP</button>
                  </p>
                </div>
                <div className="agree">
                  <p>
                    <input type="checkbox" />{" "}
                    <span className="terms-conditions">
                      I have read the term and condition
                    </span>{" "}
                    <a className="terms-conditions-link" href="#">
                      the terms of payments and withdrawl policy
                    </a>
                  </p>
                </div>
                <div className="section-submit-button">
                  <button
                    id="btn"
                    className={`payment-submit-button ${
                      isSubmitted ? "active" : ""
                    }`}
                    onClick={handleSubmitClick}
                  >
                    <p className="submit-button-text">{buttonText}</p>
                    <div className="checked">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                      >
                        <path
                          fill="transparent"
                          d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>

        <div className="payment-details">
          {showUpiForm && (
            <div className="details">
              <form action="#">
                <h4>Available to Withdrawl: &#8377;0</h4>
                <div className="methods-heading">
                  <p>Submit Your UPI Details</p>
                </div>
                <div className="withdraw-form-input">
                  <p>
                    <input
                      type="number"
                      placeholder=" &#8377;  &nbsp; Enter Amount"
                    />
                  </p>
                </div>
                <div className="withdraw-form-input">
                  {/* <p>IFSC Code<span className='red'>*</span></p> */}
                  <p>
                    <input type="text" placeholder=" Enter Mobile Number" />
                  </p>
                </div>
                <div className="withdraw-form-input">
                  {/* <p>Account Name<span className='red'>*</span></p> */}
                  <p>
                    <input type="text" placeholder=" Enter UPI ID" />
                  </p>
                </div>
                <div className="otp-div withdraw-form-input">
                  <p className="otp">Click on get OTP to verify your account</p>
                  <p>
                    <input type="number" placeholder=" +91 999000999" />
                    <button className="button-container">Get OTP</button>
                  </p>
                </div>
                <div className="agree">
                  <p>
                    <input type="checkbox" />{" "}
                    <span className="terms-conditions">
                      I have read the term and condition
                    </span>{" "}
                    <a className="terms-conditions-link" href="#">
                      the terms of payments and withdrawl policy
                    </a>
                  </p>
                </div>
                <div className="section-submit-button">
                  <button
                    id="btn"
                    className={`payment-submit-button ${
                      isSubmitted ? "active" : ""
                    }`}
                    onClick={handleSubmitClick}
                  >
                    <p className="submit-button-text">{buttonText}</p>
                    <div className="checked">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                      >
                        <path
                          fill="transparent"
                          d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>

        <div className="payment-details">
          {showWalletForm && (
            <div className="details">
              <form action="#">
                <h4>Available to Withdrawl: &#8377;0</h4>
                <div className="methods-heading">
                  <p>Submit Your Wallet Details</p>
                </div>
                <div className="withdraw-form-input">
                  <p>
                    <input
                      type="number"
                      placeholder=" &#8377;  &nbsp; Enter Amount"
                    />
                  </p>
                </div>
                <div className="withdraw-form-input">
                  {/* <p>IFSC Code<span className='red'>*</span></p> */}
                  <p>
                    <input type="text" placeholder=" Enter Your Name" />
                  </p>
                </div>
                <div className="withdraw-form-input">
                  {/* <p>Account Name<span className='red'>*</span></p> */}
                  <p>
                    <input type="text" placeholder=" Enter Mobile Number" />
                  </p>
                </div>
                <div className="otp-div withdraw-form-input">
                  <p className="otp">Click on get OTP to verify your account</p>
                  <p>
                    <input type="number" placeholder=" +91 999000999" />
                    <button className="button-container">Get OTP</button>
                  </p>
                </div>
                <div className="agree">
                  <p>
                    <input type="checkbox" />{" "}
                    <span className="terms-conditions">
                      I have read the term and condition
                    </span>{" "}
                    <a className="terms-conditions-link" href="#">
                      the terms of payments and withdrawl policy
                    </a>
                  </p>
                </div>
                <div className="section-submit-button">
                  <button
                    id="btn"
                    className={`payment-submit-button ${
                      isSubmitted ? "active" : ""
                    }`}
                    onClick={handleSubmitClick}
                  >
                    <p className="submit-button-text">{buttonText}</p>
                    <div className="checked">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                      >
                        <path
                          fill="transparent"
                          d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>





        <div className="profile-container">
        {showPreviousAccount && (
          <div className="card-container">
          {/* Bank Details Section */}
            <h1>Bank Details</h1>
            <div className="main-card">
              <div className="bank-image">
                <img src="../Images/bank.png" alt="Bank" />
              </div>
              <div className="profile-details">
                <table>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>-</td>
                      <td>{bankDetails.name}</td>
                    </tr>
                    <tr>
                      <td>A/c Number</td>
                      <td>-</td>
                      <td>{bankDetails.accountNumber}</td>
                    </tr>
                    <tr>
                      <td>IFSC Code</td>
                      <td>-</td>
                      <td>{bankDetails.ifscCode}</td>
                    </tr>
                    <tr>
                      <td>Bank Name</td>
                      <td>-</td>
                      <td>{bankDetails.bankName}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="action-buttons">
              <button className="edit-button" onClick={() => toggleEditPopup("bank")}>Edit</button>
              <button className="edit-button">Delete</button>
            </div>
          </div>
        )}

        {showPreviousAccount && (
          <div className="card-container">
            <h1>UPI Details</h1>
            <div className="main-card">
              <div className="bank-image">
                <img src="../Images/bhim-upi.png" alt="UPI" />
              </div>
              <div className="profile-details">
                <table>
                  <tbody>
                    <tr>
                      <td>UPI Id</td>
                      <td>-</td>
                      <td>{UPIDetails.upi_id}</td>
                    </tr>
                    <tr>
                      <td>Phone Number</td>
                      <td>-</td>
                      <td>{UPIDetails.phoneNumber}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="action-buttons">
              <button className="edit-button" onClick={() => toggleEditPopup("upi")}>Edit</button>
              <button className="edit-button">Delete</button>
            </div>
          </div>
        )}
        </div>
      </div>
    </>
  );
};

export default Withdraw;
