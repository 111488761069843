// GpayForm.js
import React from 'react';

const GpayForm = () => {
  return (
    <>
    {/* <h2>Add Your Details</h2> */}
    <form>
      <label>
        
        <input type="text" placeholder='Name' required />
      </label>
      <label>
        
        <input type="text" placeholder='UPI ID' required />
      </label>
      <label>
        
        <input type="text" placeholder='Number' required />
      </label>
      <button type="submit">Submit</button>
    </form>
    </>
  );
};

export default GpayForm;
