// BankForm.js
import React from 'react';
// import "../../../src/App.css";

const BankForm = () => {
  return (
    <>
    {/* <h2>Add Your Details</h2> */}
    <div className="background-color">
    <form>
      <label>
        <input type="text" placeholder='Bank Name:' required />
      </label>
      <label>
        <input type="text" placeholder='Account Holder Name:' required />
      </label>
      <label> 
        <input type="text" placeholder='Account Number:' required />
      </label>
      <label>
        <input type="text" placeholder='IFSC Code:' required />
      </label>
      <button type="submit">Submit</button>
    </form>
    </div>
    </>
  );
};

export default BankForm;
