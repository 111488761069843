import React from "react";
import "./ScrollCasino.css";
const ScrollCasino = () => {
  return (
    <>
    <div className="live-casino">
      <div className="live-casino-heading">
        <p>Top League</p>
      </div>
      <div className="scrollable-box">
        <div className="inner-box scroll-casino">
          <button className="image-setting-1"></button>
          <button className="image-setting-2"></button>
          <button className="image-setting-3"></button>
          <button className="image-setting-4"></button>
          <button className="image-setting-5"></button>
          <button className="image-setting-6"></button>
          <button className="image-setting-7"></button>
          {/* <button className="image-setting">
            <img src="../../../images/top-league-1.png" alt="" />
          </button>
          <button className="image-setting">
            <img src="../../../images/top-league-2.png" alt="" />
          </button>
          <button className="image-setting">
            <img src="../../../images/top-league-3.png" alt="" />
          </button>
          <button className="image-setting">
            <img src="../../../images/top-league-4.png" alt="" />
          </button>
          <button className="image-setting">
            <img src="../../../images/top-league-5.png" alt="" />
          </button>
          <button className="image-setting">
            <img src="../../../images/top-league-6.png" alt="" />
          </button>
          <button className="image-setting">
            <img src="../../../images/top-league-7.jpg" alt="" />
          </button> */}
        </div>
      </div>
    </div>
    <div className="casino-background">
    <div className="live-casino">
      <div className="live-casino-heading">
        <p>Casino</p>
      </div>
      <div className="scrollable-box">
        <div className="inner-box scroll-casino">
          <button className="image-setting-casino-1"></button>
          <button className="image-setting-casino-2"></button>
          <button className="image-setting-casino-3"></button>
          <button className="image-setting-casino-4"></button>
          <button className="image-setting-casino-5"></button>
          <button className="image-setting-casino-6"></button>
          <button className="image-setting-casino-7"></button>
          {/* <button className="image-setting">
            <img src="../../../images/casino-2.png" alt="" />
          </button>
          <button className="image-setting">
            <img src="../../../images/casino-3.png" alt="" />
          </button>
          <button className="image-setting">
            <img src="../../../images/casino-4.png" alt="" />
          </button>
          <button className="image-setting">
            <img src="../../../images/casino-5.png" alt="" />
          </button>
          <button className="image-setting">
            <img src="../../../images/casino-6.png" alt="" />
          </button>
          <button className="image-setting">
            <img src="../../../images/casino-7.png" alt="" />
          </button> */}
        </div>
      </div>
    </div>
    
    <div className="live-casino">
      <div className="live-casino-heading">
        <p>Live Casino</p>
      </div>
      <div className="scrollable-box">
        <div className="inner-box scroll-casino">
          <button className="image-setting-live-casino-1"></button>
          <button className="image-setting-live-casino-2"></button>
          <button className="image-setting-live-casino-3"></button>
          <button className="image-setting-live-casino-4"></button>
          <button className="image-setting-live-casino-5"></button>
          <button className="image-setting-live-casino-6"></button>
          <button className="image-setting-live-casino-7"></button>
          {/* <button className="image-setting">
            <img src="../../../images/live-casino-2.png" alt="" />
          </button>
          <button className="image-setting">
            <img src="../../../images/live-casino-3.png" alt="" />
          </button>
          <button className="image-setting">
            <img src="../../../images/live-casino-4.png" alt="" />
          </button>
          <button className="image-setting">
            <img src="../../../images/live-casino-5.png" alt="" />
          </button>
          <button className="image-setting">
            <img src="../../../images/live-casino-6.png" alt="" />
          </button>
          <button className="image-setting">
            <img src="../../../images/live-casino-7.png" alt="" />
          </button> */}
        </div>
      </div>
    </div>
    </div>
    </>
  );
};

export default ScrollCasino;
