import React from "react";
import "./OpenBets.css"; // Include the CSS in a separate file or use inline styles
// import "../../../src/App.css";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import { CiSearch } from "react-icons/ci";
import 'bootstrap/dist/css/bootstrap.min.css';


function OpenBets() {
  return (
    <>
      <Header />
      <div className="open-bet">
        <p className="open-para">
          <span>Open Bets</span>
        </p>
        <div className="option-flex">
          <ul className="options">
            <li>Show</li>
            <li>
              <select>
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option>500</option>
              </select>
            </li>
            <li>entries</li>
          </ul>
          <ul className="search">
            {/* <li>Search:</li> */}
            <li className="input">
              <input type="text" className="search-input" placeholder="search"/>
            </li>
          </ul>
        </div>
          <p className="odds">
            <span>Match Odds</span>
          </p>
          <div className="table-container">
          <table className="scrollable-table">
            <thead>
              <tr>
                <th>Sr.no</th>
                <th>Date</th>
                <th>Event Name</th>
                <th>Market Type</th>
                <th>Selection</th>
                <th>Bet Type</th>
                <th>Stack</th>
                <th>Liability</th>
                <th>Profit/Loss</th>
                <th>Round ID</th>
                <th>Place Time</th>
                <th>Settled Time</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {/* Sample data for the table */}
              <tr>
                <td>1</td>
                <td>2024-09-15 13:52:48</td>
                <td>
                  <span className="table-match">
                    INDIA vs PAK
                  </span>
                </td>
                <td className="credit">Odds</td>
                <td>Match Odds</td>
                <td>Back</td>
                <td>100</td>
                <td>100</td>
                <td>100</td>
                <td>12345</td>
                <td>2024-09-15 13:52:48</td>
                <td>2024-09-15 13:52:48</td>
                
              </tr>
              <tr>
                <td>2</td>
                <td>2024-09-15 13:52:48</td>
                <td>
                  <span className="table-match">
                  INDIA vs PAK
                  </span>
                </td>
                <td className="credit">BM</td>
                <td>BM</td>
                <td>Lay</td>
                <td>100</td>
                <td>100</td>
                <td>100</td>
                <td>12345</td>
                <td>2024-09-15 13:52:48</td>
                <td>2024-09-15 13:52:48</td>
                
              </tr>
              <tr>
                <td>2</td>
                <td>2024-09-15 13:52:48</td>
                <td>
                  <span className="table-match">
                  INDIA vs PAK
                  </span>
                </td>
                <td className="credit">Toss</td>
                <td>Toss</td>
                <td>Back</td>
                <td>100</td>
                <td>100</td>
                <td>100</td>
                <td>12345</td>
                <td>2024-09-15 13:52:48</td>
                <td>2024-09-15 13:52:48</td>
                
              </tr>

              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
          {/* <p className="open-para-entries">
            <span>Showing 1 to 1 of 1 entries</span>
          </p>
          <ul className="button-next">
            <li>
              <button className="pre">Previous</button>
            </li>
            <li>
              <button className="nxt">Next</button>
            </li>
          </ul> */}

<nav aria-label="Page navigation example" className="pagination-nav">
  <ul class="pagination">
    <li class="page-item">
      <a class="page-link" href="#" aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only"></span>
      </a>
    </li>
    <li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item"><a class="page-link" href="#">2</a></li>
    <li class="page-item"><a class="page-link" href="#">3</a></li>
    <li class="page-item">
      <a class="page-link" href="#" aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
        <span class="sr-only"></span>
      </a>
    </li>
  </ul>
</nav>




        <p className="odds">
          <span>Session</span>
        </p>

        <div className="table-container">
          <table className="scrollable-table">
            <thead>
              <tr>
                <th>Sr.no</th>
                <th>Date</th>
                <th>Event Name</th>
                <th>Market Type</th>
                <th>Selection</th>
                <th>Bet Type</th>
                <th>Stack</th>
                <th>Liability</th>
                <th>Profit/Loss</th>
                <th>Round ID</th>
                <th>Place Time</th>
                <th>Settled Time</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {/* Sample data for the table */}
              <tr>
                <td>1</td>
                <td>2024-09-15 13:52:48</td>
                <td>
                  <span className="table-match">
                    INDIA vs PAK
                  </span>
                </td>
                <td className="credit">Fancy</td>
                <td>1 Over IND</td>
                <td>Back</td>
                <td>100</td>
                <td>100</td>
                <td>100</td>
                <td>12345</td>
                <td>2024-09-15 13:52:48</td>
                <td>2024-09-15 13:52:48</td>
                
              </tr>
              <tr>
                <td>2</td>
                <td>2024-09-15 13:52:48</td>
                <td>
                  <span className="table-match">
                  INDIA vs PAK
                  </span>
                </td>
                <td className="credit">Fancy</td>
                <td>6 Over IND</td>
                <td>Lay</td>
                <td>100</td>
                <td>100</td>
                <td>100</td>
                <td>12345</td>
                <td>2024-09-15 13:52:48</td>
                <td>2024-09-15 13:52:48</td>
                
              </tr>
              <tr>
                <td>2</td>
                <td>2024-09-15 13:52:48</td>
                <td>
                  <span className="table-match">
                  INDIA vs PAK
                  </span>
                </td>
                <td className="credit">Fancy</td>
                <td>10 Over IND</td>
                <td>Back</td>
                <td>100</td>
                <td>100</td>
                <td>100</td>
                <td>12345</td>
                <td>2024-09-15 13:52:48</td>
                <td>2024-09-15 13:52:48</td>
                
              </tr>

              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
        <nav aria-label="Page navigation example " className="pagination-nav">
  <ul class="pagination">
    <li class="page-item">
      <a class="page-link" href="#" aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only"></span>
      </a>
    </li>
    <li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item"><a class="page-link" href="#">2</a></li>
    <li class="page-item"><a class="page-link" href="#">3</a></li>
    <li class="page-item">
      <a class="page-link" href="#" aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
        <span class="sr-only"></span>
      </a>
    </li>
  </ul>
</nav>
      </div>
      <br/>
      <br/>
      <Footer />
    </>
  );
}

export default OpenBets;
