import React, { useState, useRef } from "react";
import "./Deposit.css";
// import "../../../src/App.css";
import "../Withdraw/Withdraw.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaFileUpload } from "react-icons/fa";
import Header from "../header/Header";

const Deposit = () => {
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("gpay"); // Default value set to "gpay"
  const [isSubmitted, setIsSubmitted] = useState(false); // State for form submission
  const [buttonText, setButtonText] = useState("Submit"); // State for button text
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  // Function to handle button click
  const handleButtonClick = (value) => {
    setInputValue(value);
  };

  // Function to handle payment option click
  const handleClick = (option) => {
    setSelectedOption(option);
  };

  // Button values
  const buttonValues = [500, 1000, 1500, 2000, 2500, 3000];

  const listRef = useRef(null); // Ref to target the scrolling list

  const scrollLeft = () => {
    listRef.current.scrollBy({
      left: -300, // Scroll left by 150px
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    listRef.current.scrollBy({
      left: 300, // Scroll right by 150px
      behavior: "smooth",
    });
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX); // Get the starting X position of the touch
  };

  // Handle touch move
  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX); // Get the X position during the swipe
  };

  // Handle touch end
  const handleTouchEnd = () => {
    const touchDiff = touchStartX - touchEndX;

    if (touchDiff > 50) {
      // Swipe left (scroll right)
      scrollRight();
    } else if (touchDiff < -50) {
      // Swipe right (scroll left)
      scrollLeft();
    }
  };

  // Handle submit click and toggle button text & style
  const handleSubmitClick = () => {
    if (isSubmitted) {
      setButtonText("Submit"); // Reset the button text to "Submit"
      setIsSubmitted(false); // Remove the active class
    } else {
      setButtonText("Thanks"); // Change the text to "Thanks"
      setIsSubmitted(true); // Set the button to active
    }
  };

  return (
    <>
      <Header />
      <div className="deposit-withdraw">
        <p className="deposit">
          <span>Deposit</span>
        </p>

        <div className="payments-symbol">
          <h3 className="payments-options">Payment Options</h3>

          <div className="scroll-container">
            <ul className="payments">
              {/* Update the list items to call handleClick with the respective option */}
              <li className="click" onClick={() => handleClick("bank")}>
                <img src="../Images/bank-transfer.png" alt="Bank" className="upi" />
              </li>
              <li className="click" onClick={() => handleClick("gpay")}>
                <img src="../Images/G-pay.png" alt="Gpay" className="upi" />
              </li>
              <li className="click" onClick={() => handleClick("phonepe")}>
                <img
                  src="../Images/phonepe.png"
                  alt="PhonePe"
                  className="upi"
                />
              </li>
              <li className="click" onClick={() => handleClick("upi")}>
                <img src="../Images/UPI-Color.png" alt="UPI" className="upi" />
              </li>
              <li className="click" onClick={() => handleClick("paytm")}>
                <img src="../Images/paytm.png" alt="Paytm" className="upi" />
              </li>
              <li className="click" onClick={() => handleClick("paypal")}>
                <img src="../Images/paypal.png" alt="Paypal" className="upi" />
              </li>
              <li className="click" onClick={() => handleClick("applepay")}>
                <img
                  src="../Images/apple-pay.png"
                  alt="Applepay"
                  className="upi"
                />
              </li>
            </ul>
          </div>

          <p className="see-more">
            1. Deposit money only in the below available accounts to get the
            fastest credit and avoid possible delays.
          </p>
        </div>

        {/* Conditionally render the divs based on the selected option */}
        <div className="payment-details">
          {selectedOption === "bank" && (
            <div className="details">
              <h4>Current Available Balance : &#8377; 0</h4>
              <h3>Payments Details</h3>
              <div className="account">
                <p>Account Number</p>
                <span>99999333339999</span>
              </div>
              <div className="account">
                <p>IFSC</p>
                <span>KARB000321</span>
              </div>
              <div className="account">
                <p>Account Name</p>
                <span>MOHAD SAHEED</span>
              </div>
              <div className="account">
                <p>Bank Name</p>
                <span>KARANATKA BANK</span>
              </div>

              <div className="input-container">
                <h3>
                  Amount<span className="red">*</span>
                </h3>
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="&#8377;Enter amount"
                  className="amount-input"
                />
              </div>

              <div className="button-container">
                {buttonValues.map((value, index) => (
                  <button
                    key={index}
                    className="amount-button"
                    onClick={() => handleButtonClick(value)}
                  >
                    {value}
                  </button>
                ))}
              </div>
              <div className="payment-slip">
                <h3>
                  Upload your payment slip below <span className="red">*</span>
                </h3>
                <div className="file-upload">
                  <label className="file-upload-label">
                    <input type="file" className="file-input" />
                    <FaFileUpload className="file-icon" />
                    <span className="file-upload-text">Upload File</span>
                  </label>
                </div>
                <div className="unique-transaction">
                  <h3>
                    Unique Transaction Reference <span className="red">*</span>
                  </h3>
                  <input
                    className="unique"
                    type="text"
                    placeholder="6 to 23 Digit UTR RRN Number"
                  />
                </div>
              </div>
              <div className="agree">
                <p>
                  <input type="checkbox" />{" "}
                  <span className="terms-conditions">
                    I have read the term and condition
                  </span>{" "}
                  <a className="terms-conditions-link" href="#">
                    the terms of payments and deposit policy
                  </a>
                </p>
              </div>

              <div className="section-submit-button">
                <button
                  id="btn"
                  className={`payment-submit-button ${
                    isSubmitted ? "active" : ""
                  }`}
                  onClick={handleSubmitClick}
                >
                  <p className="submit-button-text">{buttonText}</p>
                  <div className="checked">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                      <path
                        fill="transparent"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      ></path>
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          )}

          {selectedOption === "gpay" && (
            <div className="details">
              <h4>Current Available Balance : &#8377; 0</h4>
              <h3>Payments Details</h3>
              <div className="account">
                <p>Mobile Number</p>
                <span>8769875330</span>
              </div>
              <div className="account">
                <p>Account Name</p>
                <span>Vikram Pandia</span>
              </div>

              <div className="input-container">
                <h3>
                  Amount<span className="red">*</span>
                </h3>
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="&#8377;Enter amount"
                  className="amount-input"
                />
              </div>

              <div className="button-container">
                {buttonValues.map((value, index) => (
                  <button
                    key={index}
                    className="amount-button"
                    onClick={() => handleButtonClick(value)}
                  >
                    {value}
                  </button>
                ))}
              </div>
              <div className="payment-slip">
                <h3>
                  Upload your payment slip below <span className="red">*</span>
                </h3>
                <div className="file-upload">
                  <label className="file-upload-label">
                    <input type="file" className="file-input" />
                    <FaFileUpload className="file-icon" />
                    <span className="file-upload-text">Upload File</span>
                  </label>
                </div>
                <div className="unique-transaction">
                  <h3>
                    Unique Transaction Reference <span className="red">*</span>
                  </h3>
                  <input
                    className="unique"
                    type="text"
                    placeholder="6 to 23 Digit UTR RRN Number"
                  />
                </div>
              </div>
              <div className="agree">
                <p>
                  <input type="checkbox" />{" "}
                  <span className="terms-conditions">
                    I have read the term and condition
                  </span>{" "}
                  <a className="terms-conditions-link" href="#">
                    the terms of payments and deposit policy
                  </a>
                </p>
              </div>

              <div className="section-submit-button">
                <button
                  id="btn"
                  className={`payment-submit-button ${
                    isSubmitted ? "active" : ""
                  }`}
                  onClick={handleSubmitClick}
                >
                  <p className="submit-button-text">{buttonText}</p>
                  <div className="checked">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                      <path
                        fill="transparent"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      ></path>
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          )}

          {selectedOption === "phonepe" && (
            <div className="details">
              <h4>Current Available Balance : &#8377; 0</h4>
              <h3>Payments Details</h3>
              <div className="account">
                <p>Mobile Number</p>
                <span>1234567890</span>
              </div>
              <div className="account">
                <p>Account Name</p>
                <span>User 1</span>
              </div>

              <div className="input-container">
                <h3>
                  Amount<span className="red">*</span>
                </h3>
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="&#8377;Enter amount"
                  className="amount-input"
                />
              </div>

              <div className="button-container">
                {buttonValues.map((value, index) => (
                  <button
                    key={index}
                    className="amount-button"
                    onClick={() => handleButtonClick(value)}
                  >
                    {value}
                  </button>
                ))}
              </div>
              <div className="payment-slip">
                <h3>
                  Upload your payment slip below <span className="red">*</span>
                </h3>
                <div className="file-upload">
                  <label className="file-upload-label">
                    <input type="file" className="file-input" />
                    <FaFileUpload className="file-icon" />
                    <span className="file-upload-text">Upload File</span>
                  </label>
                </div>
                <div className="unique-transaction">
                  <h3>
                    Unique Transaction Reference <span className="red">*</span>
                  </h3>
                  <input
                    className="unique"
                    type="text"
                    placeholder="6 to 23 Digit UTR RRN Number"
                  />
                </div>
              </div>
              <div className="agree">
                <p>
                  <input type="checkbox" />{" "}
                  <span className="terms-conditions">
                    I have read the term and condition
                  </span>{" "}
                  <a className="terms-conditions-link" href="#">
                    the terms of payments and deposit policy
                  </a>
                </p>
              </div>

              <div className="section-submit-button">
                <button
                  id="btn"
                  className={`payment-submit-button ${
                    isSubmitted ? "active" : ""
                  }`}
                  onClick={handleSubmitClick}
                >
                  <p className="submit-button-text">{buttonText}</p>
                  <div className="checked">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                      <path
                        fill="transparent"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      ></path>
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          )}

          {selectedOption === "upi" && (
            <div className="details">
              {/* <h4>upi</h4> */}
              <h4>Current Available Balance : &#8377; 0</h4>
              <p>
                <h3>Payments Details</h3>
              </p>
              <div className="account">
                <p>Upi Id</p>
                <span>12342345@ybl</span>
              </div>
              <div className="account">
                <p>Account Name</p>
                <span>User 2</span>
              </div>

              <div className="input-container">
                <h3>
                  Amount<span className="red">*</span>
                </h3>
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="&#8377;Enter amount"
                  className="amount-input"
                />
              </div>

              <div className="button-container">
                {buttonValues.map((value, index) => (
                  <button
                    key={index}
                    className="amount-button"
                    onClick={() => handleButtonClick(value)}
                  >
                    {value}
                  </button>
                ))}
              </div>
              <div className="payment-slip">
                <h3>
                  Upload your payment slip below <span className="red">*</span>
                </h3>
                <div className="file-upload">
                  <label className="file-upload-label">
                    <input type="file" className="file-input" />
                    <FaFileUpload className="file-icon" />
                    <span className="file-upload-text">Upload File</span>
                  </label>
                </div>
                <div className="unique-transaction">
                  <h3>
                    Unique Transaction Raference <span className="red">*</span>
                  </h3>
                  <input
                    className="unique"
                    type="text"
                    placeholder="6 to 23 Digit UTR RRN Number"
                  />
                </div>
              </div>
              <div className="agree">
                <p>
                  <input type="checkbox" className="checkbox" /> I have read and
                  agree with{" "}
                  <a href="#">the terms of payments and deposit policy</a>
                </p>
              </div>
              {/* Button */}

              <div className="section-submit-button">
                <button
                  id="btn"
                  className={`payment-submit-button ${
                    isSubmitted ? "active" : ""
                  }`}
                  onClick={handleSubmitClick}
                >
                  <p className="submit-button-text">{buttonText}</p>
                  <div className="checked">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                      <path
                        fill="transparent"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      ></path>
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          )}

          {selectedOption === "paytm" && (
            <div className="details">
              {/* <h4>phone-pay</h4> */}
              <h4>Current Available Balance : &#8377; 0</h4>
              <p>
                <h3>Payments Details</h3>
              </p>
              <div className="account">
                <p>Mobile Number</p>
                <span>9876521231</span>
              </div>
              <div className="account">
                <p>Account Name</p>
                <span>User 3</span>
              </div>

              <div className="input-container">
                <h3>
                  Amount<span className="red">*</span>
                </h3>
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="&#8377;Enter amount"
                  className="amount-input"
                />
              </div>

              <div className="button-container">
                {buttonValues.map((value, index) => (
                  <button
                    key={index}
                    className="amount-button"
                    onClick={() => handleButtonClick(value)}
                  >
                    {value}
                  </button>
                ))}
              </div>
              <div className="payment-slip">
                <h3>
                  Upload your payment slip below <span className="red">*</span>
                </h3>
                <div className="file-upload">
                  <label className="file-upload-label">
                    <input type="file" className="file-input" />
                    <FaFileUpload className="file-icon" />
                    <span className="file-upload-text">Upload File</span>
                  </label>
                </div>
                <div className="unique-transaction">
                  <h3>
                    Unique Transaction Raference <span className="red">*</span>
                  </h3>
                  <input
                    className="unique"
                    type="text"
                    placeholder="6 to 23 Digit UTR RRN Number"
                  />
                </div>
              </div>
              <div className="agree">
                <p>
                  <input type="checkbox" />{" "}
                  <span className="terms-conditions">
                    I have read the term and condition
                  </span>{" "}
                  <a className="terms-conditions-link" href="#">
                    the terms of payments and deposit policy
                  </a>
                </p>
              </div>
              {/* Button */}
              <div className="section-submit-button">
                <button
                  id="btn"
                  className={`payment-submit-button ${
                    isSubmitted ? "active" : ""
                  }`}
                  onClick={handleSubmitClick}
                >
                  <p className="submit-button-text">{buttonText}</p>
                  <div className="checked">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                      <path
                        fill="transparent"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      ></path>
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          )}

          {selectedOption === "paypal" && (
            <div className="details">
              <h4>Current Available Balance : &#8377; 0</h4>
              <p>
                <h3>Payments Details</h3>
              </p>
              <div className="account">
                <p>Account Number</p>
                <span>99999333339999</span>
              </div>
              <div className="account">
                <p>Mobile Number</p>
                <span>8765432123</span>
              </div>
              <div className="account">
                <p>IFSC</p>
                <span>KARB000321</span>
              </div>
              <div className="account">
                <p>Account Name</p>
                <span>User 3</span>
              </div>
              <div className="account">
                <p>Bank Name</p>
                <span>KARANATKA BANK</span>
              </div>

              <div className="input-container">
                <h3>
                  Amount<span className="red">*</span>
                </h3>
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="&#8377;Enter amount"
                  className="amount-input"
                />
              </div>

              <div className="button-container">
                {buttonValues.map((value, index) => (
                  <button
                    key={index}
                    className="amount-button"
                    onClick={() => handleButtonClick(value)}
                  >
                    {value}
                  </button>
                ))}
              </div>
              <div className="payment-slip">
                <h3>
                  Upload your payment slip below <span className="red">*</span>
                </h3>
                <div className="file-upload">
                  <label className="file-upload-label">
                    <input type="file" className="file-input" />
                    <FaFileUpload className="file-icon" />
                    <span className="file-upload-text">Upload File</span>
                  </label>
                </div>
                <div className="unique-transaction">
                  <h3>
                    Unique Transaction Reference <span className="red">*</span>
                  </h3>
                  <input
                    className="unique"
                    type="text"
                    placeholder="6 to 23 Digit UTR RRN Number"
                  />
                </div>
              </div>
              <div className="agree">
                <p>
                  <input type="checkbox" />{" "}
                  <span className="terms-conditions">
                    I have read the term and condition
                  </span>{" "}
                  <a className="terms-conditions-link" href="#">
                    the terms of payments and withdrawal policy
                  </a>
                </p>
              </div>
              {/* Button */}
              <div className="section-submit-button">
                <button
                  id="btn"
                  className={`payment-submit-button ${
                    isSubmitted ? "active" : ""
                  }`}
                  onClick={handleSubmitClick}
                >
                  <p className="submit-button-text">{buttonText}</p>
                  <div className="checked">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                      <path
                        fill="transparent"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      ></path>
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          )}


          {selectedOption === "applepay" && (
            <div className="details">
              {/* <h4>phone-pay</h4> */}
              <h4>Current Available Balance : &#8377; 0</h4>
              <p>
                <h3>Payments Details</h3>
              </p>
              <div className="account">
                <p>Mobile Number</p>
                <span>99999333339999</span>
              </div>
              <div className="account">
                <p>Account Name</p>
                <span>User 4</span>
              </div>

              <div className="input-container">
                <h3>
                  Amount<span className="red">*</span>
                </h3>
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="&#8377;Enter amount"
                  className="amount-input"
                />
              </div>

              <div className="button-container">
                {buttonValues.map((value, index) => (
                  <button
                    key={index}
                    className="amount-button"
                    onClick={() => handleButtonClick(value)}
                  >
                    {value}
                  </button>
                ))}
              </div>
              <div className="payment-slip">
                <h3>
                  Upload your payment slip below <span className="red">*</span>
                </h3>
                <div className="file-upload">
                  <label className="file-upload-label">
                    <input type="file" className="file-input" />
                    <FaFileUpload className="file-icon" />
                    <span className="file-upload-text">Upload File</span>
                  </label>
                </div>
                <div className="unique-transaction">
                  <h3>
                    Unique Transaction Raference <span className="red">*</span>
                  </h3>
                  <input
                    className="unique"
                    type="text"
                    placeholder="6 to 23 Digit UTR RRN Number"
                  />
                </div>
              </div>
              <div className="agree">
                <p>
                  <input type="checkbox" />{" "}
                  <span className="terms-conditions">
                    I have read the term and condition
                  </span>{" "}
                  <a className="terms-conditions-link" href="#">
                    the terms of payments and deposit policy
                  </a>
                </p>
              </div>
              {/* Button */}
              <div className="section-submit-button">
                <button
                  id="btn"
                  className={`payment-submit-button ${
                    isSubmitted ? "active" : ""
                  }`}
                  onClick={handleSubmitClick}
                >
                  <p className="submit-button-text">{buttonText}</p>
                  <div className="checked">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                      <path
                        fill="transparent"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      ></path>
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          )}

          {/* You can add more payment option forms here as needed */}
        </div>
      </div>
    </>
  );
};

export default Deposit;
