import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import './FootballList.css';
// import "../../../src/App.css";
// import "../CricketList/CricketList.css";
import { BiSolidCricketBall, BiFootball } from "react-icons/bi";
import Header from '../header/Header';
import Footer from '../Footer/Footer';

const FootballList = () => {
    const [currentTime, setCurrentTime] = useState(new Date('2024-08-26T04:30'));
    const scrollRefs = useRef([]);
  
    const navigate = useNavigate();
  
    const initializeScrollRefs = (index) => {
      if (!scrollRefs.current[index]) {
        scrollRefs.current[index] = React.createRef();
      }
    };
  
    const handleScroll = (scrollingRef) => {
      scrollRefs.current.forEach(ref => {
        if (ref.current && ref !== scrollingRef) {
          ref.current.scrollLeft = scrollingRef.current.scrollLeft;
        }
      });
    };
  
    // Move handleMatchClick here, outside of useEffect
    const handleMatchClick = () => {
      navigate('/matchupdates'); // Navigate to MatchUpdates
    };
  
    useEffect(() => {
      scrollRefs.current.forEach((ref) => {
        if (ref.current) {
          ref.current.addEventListener('scroll', () => handleScroll(ref));
        }
      });
  
      return () => {
        scrollRefs.current.forEach((ref) => {
          if (ref.current) {
            ref.current.removeEventListener('scroll', () => handleScroll(ref));
          }
        });
      };
    }, []);
  
    return (
        <>
        <Header/>
            <div className='main cricket-list'>
        
                {/* Football Section */}
      <div className="cricket">
        <div className="header-box">
          <BiFootball size={25} color="orange" style={{ marginRight: 5, marginLeft: 5, marginTop: 3 }} />
          <span>Football</span>
        </div>
        <div className="bet-numbers">
          <div className="box"><span>1</span></div>
          <div className="box"><span>X</span></div>
          <div className="box"><span>2</span></div>
        </div>
      </div>

      {/* Football Event Boxes */}
      {[...Array(4)].map((_, i) => {
        const teams = [
          { teamA: 'Thunder Strikers', teamB: 'Blaze Titans' },
          { teamA: 'Lightning Raiders', teamB: 'Phoenix Warriors' },
          { teamA: 'Iron Giants', teamB: 'Crimson Crushers' },
          { teamA: 'Rampage Rovers', teamB: 'Avalanche United' }
        ];

        const teamA = teams[i % teams.length].teamA;
        const teamB = teams[i % teams.length].teamB;

        initializeScrollRefs(i + 5); // Initialize scroll ref for the current event box

        return (
          <div className="event-box" key={i + 5} onClick={() => handleMatchClick(currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }))}>
            <div className="date-time-box">
              <p>{i % 2 === 0 ? 'Tomorrow' : currentTime.toLocaleDateString()}</p>
              <span className="date">{currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
            </div>
            <div className="matches" onClick={handleMatchClick}>
              <div className="a">{teamA} vs {teamB}</div>
            </div>
            <div
              className="event-numbers"
              ref={scrollRefs.current[i + 5]} // Assign the ref to the event box
              onScroll={() => handleScroll(scrollRefs.current[i + 5])} // Handle scrolling
            >
              <div className="box">
                <span>2.7</span>
              </div>
              <div className="box">
                <span>1.5</span>
              </div>
              <div className="box">
                <span>10.8</span>
              </div>
              <div className="box-pink">
                <span>2.3</span>
              </div>
              <div className="box-pink">
                <span>1.28</span>
              </div>
              <div className="box-pink">
                <span>3.2</span>
              </div>
            </div>
          </div>
        );
      })}
            </div>
            <Footer/>
      </>
    );
}

export default FootballList;
