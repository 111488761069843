import React, { useState } from "react";
import "./Settings.css";
// import "../../../src/App.css";
import Header from "../header/Header";
import Footer from "../Footer/Footer";

function Settings() {
  // State for input values
  const [inputValues, setInputValues] = useState([
    "1000",
    "2000",
    "5000",
    "10000",
    "100000",
    "500000",
  ]);
  // State to determine if inputs are editable
  const [isEditable, setIsEditable] = useState(false);

  // Function to handle input changes
  const handleChange = (index, event) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = event.target.value;
    setInputValues(newInputValues);
  };

  // Function to toggle edit mode
  const toggleEdit = () => {
    setIsEditable(!isEditable); // Toggle the editable state
  };

  return (
    <div>
      <Header />
      <div className="setting">
        <div className="settings-container">
          <span>Settings</span>
        </div>
        <p className="odds">
            <span>Edit Stakes</span>
          </p>
        <div className="edit-stakes">
          {inputValues.map((value, index) => (
            <label key={index}>
              <input
                type="text"
                value={value} // Bind each input to its respective value from the array
                onChange={(event) => handleChange(index, event)} // Handle input changes
                disabled={!isEditable} // Disable input if not in edit mode
              />
            </label>
          ))}
        </div>

        {/* Edit button */}
        <div className="edit-button">
        <button onClick={toggleEdit} className="edit-btn">
          {isEditable ? "Save" : "Edit"}
        </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Settings;
