import React, { useState } from "react";
import "./AddWithdrawDetails.css"; 
// import "../../../src/App.css";
import { FaTimes } from "react-icons/fa"; // Importing the cross icon from react-icons
import BankForm from "./BankForm";
import PaytmForm from "./PaytmForm";
import UpiForm  from "./UpiForm";
import PhonepeForm from "./PhonepeForm";
import GpayForm from  "./GpayForm"

const AddWithdrawDetails = ({ togglePopup }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleDropdownChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const renderForm = () => {
    switch (selectedOption) {
      case "Bank":
        return <BankForm />;
      case "Paytm":
        return <PaytmForm />;
      case "Phonepe":
        return <PhonepeForm />;
      case "UPI":
        return <UpiForm />;
      case "G-pay":
        return <GpayForm />;
      // Add other cases for G-pay, UPI, etc.
      default:
        return null;
    }
  };

  return (
    <>
      <div className="popup-overlay">
        <div className="popup-content">
          {/* Cross button to close the popup */}
          <button className="close-icon" onClick={togglePopup}>
            <FaTimes size={20} />
          </button>
          <h4>{selectedOption ? `Fill Your ${selectedOption}  Details` : "Select Your Method"}</h4>
          <div className="selection-menu">
          {selectedOption === "" && (
              <select value={selectedOption} onChange={handleDropdownChange}>
                <option value="">Select Payment Method</option>
                <option value="Bank">Bank</option>
                <option value="Paytm">Paytm</option>
                <option value="Phonepe">Phonepe</option>
                <option value="G-pay">G-pay</option>
                <option value="UPI">UPI</option>
              </select>
            )}
          </div>
          <div className="form-container">
            {renderForm()}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddWithdrawDetails;
