import { useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';

const TableRow = ({ i, row }) => {
    console.log('row', row);

    function getDisplayDate() {
        const openDateObj = new Date(row.open_date); // Convert openDate string to Date object
        const today = new Date();
        const tomorrow = new Date(today);

        today.setHours(0, 0, 0, 0);
        tomorrow.setDate(today.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);

        // Compare dates
        if (openDateObj.toDateString() === today.toDateString()) {
            return "Today";
        } else if (openDateObj.toDateString() === tomorrow.toDateString()) {
            return "Tomorrow";
        } else {
            return openDateObj.toLocaleDateString(); // Show formatted date if not today or tomorrow
        }
    }

    const getDisplayTime = () => {
        const openDateObj = new Date(row.open_date);
        return openDateObj.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    };


    return (
        <>
            <div className="event-box" key={i}>
                <div className="date-time-box" >
                    {row?.is_inplay == 'True' ?
                        (
                            <span className='in-play-effect'>In-Play</span>
                        )
                        :
                        (
                            <>
                                <p>{getDisplayDate()}</p>
                                <span className="date">{getDisplayTime()}</span>
                            </>
                        )
                    }
                </div>
                <Link to={`/matchupdates/${row.event_id}/${row.is_inplay == 'True' ? "Inplay" : "Going Inplay"}`} className='matches'>
                    <div className="a">{row?.event_name}</div>
                </Link>
                <div
                    className="event-numbers"
                >
                    <div className="box">
                        <span>3.9</span>
                    </div>
                    <div className="box">
                        <span>3.55</span>
                    </div>
                    <div className="box">
                        <span>2.16</span>
                    </div>
                    <div className="box-pink">
                        <span>3.95</span>
                    </div>
                    <div className="box-pink">
                        <span>3.6</span>
                    </div>
                    <div className="box-pink">
                        <span>2.18</span>
                    </div>
                </div>
            </div>
        </>
    )


}

export default TableRow;
