import React, { useState } from 'react';
import './LoginPopup.css'; // Import the CSS file
import { IoMdCloseCircle } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { FaMobileAlt, FaCaretDown, FaUser, FaEye, FaEyeSlash } from "react-icons/fa";

const LoginPopup = ({ onClose }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState('mobile');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();  // Use navigate hook from react-router-dom

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputTypeChange = (type) => {
    setInputType(type);
    setDropdownOpen(false);
    setUsername('');
  };

  const handleCreateAccount = () => {
    navigate('/signup'); // Navigate to the signup page
  };

  return (
    <div className="login-popup-overlay" onClick={onClose}>
      <div className="login-popup-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-btn" onClick={onClose}>
          <IoMdCloseCircle />
        </span>
        <img className='form-logo' src="../logo.png" alt="" />
        <form>
          <label className="input-label-above">
            {inputType === 'mobile' ? 'Mobile Number' : 'User ID'}
          </label>
          <div className="username">
            <span className="input-label">
              {inputType === 'mobile' ? '+91' : 'ID'}
            </span>
            <input
              className='login-input'
              type="text"
              name="username"
              placeholder={inputType === 'mobile' ? 'Enter mobile number' : 'Enter user ID'}
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <span className="icon" onClick={() => setDropdownOpen(!dropdownOpen)}>
              <FaMobileAlt size={30} color="#e9b02e" /> <FaCaretDown size={20} color="#e9b02e" />
            </span>
            {dropdownOpen && (
              <div className="dropdown-menu">
                <div onClick={() => handleInputTypeChange('mobile')}>
                  <FaMobileAlt size={20} color="#e9b02e" /> Mobile Number
                </div>
                <div onClick={() => handleInputTypeChange('userid')}>
                  <FaUser size={20} color="#e9b02e" /> User ID
                </div>
              </div>
            )}
          </div>
          <span className="password-label">Password</span>
          <div className="password">
            <input
              className='password-input'
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder="Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span className="password-toggle" onClick={togglePasswordVisibility}>
              {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
            </span>
          </div>
          <button className="pushable submit-btn">
            <span className="front">Login</span>
          </button>
        </form>
        <span className='other-login'>Or login with</span>
        <span className="other-button">
          <button className='google'><img src="./Images/google.png" alt="" className='google-image' /> Google</button>
          <button className='whatsapp'><img src="./Images/whatsapp.png" alt="" className='whatsapp-image' /> WhatsApp</button>
        </span>
        <span className='new-account' onClick={() => navigate('/signup')}>New User? Create Account</span>
      </div>
    </div>
  );
};

export default LoginPopup;
