import React from "react";
import "./ChangePassword.css";
// import "../../../src/App.css";
import Header from "../header/Header";
import Footer from "../Footer/Footer";

const ChangePassword = () => {
  return (
    <div>
      <Header />
      <div className="account-statement">
      <p className="open-para">
        <span>Change Password</span>
      </p>
      <div className="change-password-container">
        <form action="#">
          <input
            type="text"
            name="oldPassword"
            placeholder="Enter Old Password"
            />
          <input
            type="text"
            name="oldPassword"
            placeholder="Enter New Password"
            />
          <input
            type="text"
            name="oldPassword"
            placeholder="Enter New Password Again"
            />
          <button className="change-password-button">Submit</button>
        </form>
      </div>
</div>
      <Footer />
    </div>
  );
};

export default ChangePassword;
