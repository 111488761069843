import React from 'react';
import './ScrollGames.css';
// import "../../../src/App.css";

const ScrollGames = (props) => {
  return (
    <div className="scrollable-box">
      <div className="inner-box">
        <button className='box-button' type="button" value={4} onClick={(e) => props?.setEventType(e.target.value)}>Cricket</button>
        <button className='box-button-1' type="button" value={1} onClick={(e) => props?.setEventType(e.target.value)}>Football</button>
        <button className='box-button-3' type="button" value={2} onClick={(e) => props?.setEventType(e.target.value)}>Tennis</button>
        <button className='box-button-2' type="button">Live Casino</button>
        <button className='box-button-4' type="button">Horse Racing</button>
        <button className='box-button-5' type="button">Evolution</button>
        <button className='box-button-6' type="button">Slot Games</button>
        <button className='box-button-7' type="button">Fortune Spin</button>
      </div>
    </div>
  );
}

export default ScrollGames;