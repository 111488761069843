import React, { useState } from "react";
import "./ProfitAndLoss.css";
// import "../../../src/App.css";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import ExposureHeader from "../ExposureHeader/ExposureHeader";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from 'react-router-dom';


const ProfitAndLoss = () => {

  const [profitandloss2, setProfitAndLoss2] = useState(false);
  const navigate = useNavigate();

  const handleProfitAndLoss2 = () =>{
    setProfitAndLoss2(true)
    navigate('/profitandloss2')
  }
  return (
    <>
      <Header />
      {/* <ExposureHeader/> */}
      <div className="account-statment">
        <p className="open-para">
          <span>Profit And Loss</span>
        </p>

        <div className="option-flex">
          <ul className="options">
            <li>Show</li>
            <li>
              <select>
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option>500</option>
              </select>
            </li>
            <li>entries</li>
          </ul>
          <ul className="search">
            {/* <li>Search:</li> */}
            <li className="input">
              <input type="text" className="search-input" placeholder="search"/>
            </li>
          </ul>
        </div>

        
          <div className="dropdown self-dropdown">
            <select className="select-dropdown">
              <option>
                <button className="account-statement-button">All</button>
              </option>
              <option>
                <button className="account-statement-button">Cricket</button>
              </option>
              <option>
                <button className="account-statement-button">
                  Tennis
                </button>
              </option>
              <option>
                <button className="account-statement-button">Soccer</button>
              </option>
              <option>
                <button className="account-statement-button">Casino</button>
              </option>
            </select>
          
        </div>
        <div className="form">
          <form>
            <div className="date-time">
              <input type="date" className="d-and-t"  />
              <input type="date" className="d-and-t" />
            </div>
            <div className="new-buttons">
            <input type="submit" className="btn-submit" />
            <input type="reset" className="btn-submit" />
            </div>
          </form>
        </div>

      </div>


        <div className="table-container">
          <table className="scrollable-table">
            <thead>
              <tr>
                <th>Sr.no</th>
                <th>Date</th>
                <th>Event Type</th>
                <th>Event Name</th>
                <th>Match P & L</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {/* Sample data for the table */}
              <tr>
                <td>1</td>
                <td>2024-09-15 13:52:48</td>
                <td>Cricket</td>
                <td>
                  <button className="table-match" onClick={handleProfitAndLoss2}>
                    INDIA vs PAK
                  </button>
                </td>
                <td className="credit">100.00</td>
                
              </tr>
              <tr>
                <td>2</td>
                <td>2024-09-15 13:52:48</td>
                <td>Cricket</td>
                <td>
                  <button className="table-match" onClick={handleProfitAndLoss2}>
                    Australia vs New Zealand
                  </button>
                </td>
                <td className="credit">100.00</td>
                
              </tr>
              <tr>
                <td>3</td>
                <td>2024-09-15 13:52:48</td>
                <td>Casino</td>
                <td>
                  <button className="table-match" onClick={handleProfitAndLoss2}>
                  Teen Patti
                  </button>
                </td>
                <td className="credit">100.00</td>
                
              </tr>

              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
        
      <Footer />
    </>
  );
};

export default ProfitAndLoss;
