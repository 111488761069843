import React from 'react';
import './ExposureHeader.css';
// import "../../../src/App.css";
import { HiCurrencyRupee } from "react-icons/hi";
import Helper from "../../helper";

const ExposureHeader = () => {
  const userInfo = Helper();

  return (
    <>
      {
        userInfo &&
        <div>
          <div className="main-header">
            <div className="coin"><HiCurrencyRupee size={20} color=" #FFD700" style={{ marginRight: 5, marginLeft: 0, marginTop: 1 }} /></div><span className='main-balance'>Main: <p>100000.00</p></span>
            <span className='exposure-balance'>Exposure: <p>0.00</p></span>
          </div>
        </div>
      }
    </>
  );
}

export default ExposureHeader;
