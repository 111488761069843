import React from "react";
import Lottie from "lottie-react";
import "../PlaceBet/PlaceBet.css";
import successAnimation from "../../assets/success-animation.json" // Adjust the path as necessary
import "./PlaceBetSuccessfull.css"; // Add your styling here
// import "../../../src/App.css";

const PlaceBetSuccessfull = ({ message, onClose }) => {
  return (
    <div className="success-popup">
      <div className="success-content">
        <div className="message">
        <Lottie animationData={successAnimation} loop={false} className="success-sign" />
        <p className="var">{message}</p>
        </div>
        <button className="close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default PlaceBetSuccessfull;
