import React from 'react';
import './Empty.css'

const Empty = () => {
  return (
    <div className='empty-div'>
      
    </div>
  );
}

export default Empty;
