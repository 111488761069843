import React from "react";
import "./ProfitAndLoss.css";
// import "../../../src/App.css";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import ExposureHeader from "../ExposureHeader/ExposureHeader";
import "bootstrap/dist/css/bootstrap.min.css";

const ProfitAndLoss3 = () => {
  return (
    <>
      <Header />
      {/* <ExposureHeader/> */}
      <div className="account-statment">
        <p className="open-para">
          <span>Profit And Loss</span>
        </p>

        <div className="option-flex">
          <ul className="options">
            <li>Show</li>
            <li>
              <select>
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option>500</option>
              </select>
            </li>
            <li>entries</li>
          </ul>
          <ul className="search">
            {/* <li>Search:</li> */}
            <li className="input">
              <input type="text" className="search-input" placeholder="search"/>
            </li>
          </ul>
        </div>
        

      </div>


        <div className="table-container">
          <table className="scrollable-table">
            <thead>
              <tr>
                <th>Sr.no</th>
                <th>Date</th>
                <th>Event Name</th>
                <th>Market Type</th>
                <th>Selection</th>
                <th>Bet Type</th>
                <th>Stack</th>
                <th>Liability</th>
                <th>Profit/Loss</th>
                <th>Round ID</th>
                <th>Place Time</th>
                <th>Settled Time</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {/* Sample data for the table */}
              <tr>
                <td>1</td>
                <td>2024-09-15 13:52:48</td>
                <td>
                  <span className="table-match">
                    INDIA vs PAK
                  </span>
                </td>
                <td className="credit">Odds</td>
                <td>Match Odds</td>
                <td>Back</td>
                <td>100</td>
                <td>100</td>
                <td>100</td>
                <td>12345</td>
                <td>2024-09-15 13:52:48</td>
                <td>2024-09-15 13:52:48</td>
                
              </tr>
              <tr>
                <td>2</td>
                <td>2024-09-15 13:52:48</td>
                <td>
                  <span className="table-match">
                  INDIA vs PAK
                  </span>
                </td>
                <td className="credit">Fancy</td>
                <td>6 Over IND</td>
                <td>Lay</td>
                <td>100</td>
                <td>100</td>
                <td>100</td>
                <td>12345</td>
                <td>2024-09-15 13:52:48</td>
                <td>2024-09-15 13:52:48</td>
                
              </tr>
              <tr>
                <td>2</td>
                <td>2024-09-15 13:52:48</td>
                <td>
                  <span className="table-match">
                  INDIA vs PAK
                  </span>
                </td>
                <td className="credit">Fancy</td>
                <td>10 Over IND</td>
                <td>Back</td>
                <td>100</td>
                <td>100</td>
                <td>100</td>
                <td>12345</td>
                <td>2024-09-15 13:52:48</td>
                <td>2024-09-15 13:52:48</td>
                
              </tr>

              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
        
      <Footer />
    </>
  );
};

export default ProfitAndLoss3;
