import React, { useState, useContext, useEffect } from "react";
import "./MatchUpdates.css";
import "../PlaceBet/PlaceBet.css";
// import "../../../src/App.css";
import { IoMdClose } from "react-icons/io"; // Importing close icon
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import { IoInformationCircleOutline } from "react-icons/io5";
import { FaPlayCircle } from "react-icons/fa";
import { MdLiveTv } from "react-icons/md";
import FancyMarket from "../FancyMarket/FancyMarket";
import PlaceBetSuccessfull from "../PlaceBetSuccesfull/PlaceBetSuccessfull";
import Empty from "../EmptyDiv/Empty";
import { TimeContext } from "../TimeContext/TimeContext";
import { getMatchData } from '../../redux/slice/eventData/eventDataSlice';
import { getMatchFancyData } from '../../redux/slice/fancyData/fancyDataSlice';
import { getUserBal } from '../../redux/slice/user/userSlice';
import Helper from "../../helper";
import { useSelector, useDispatch } from 'react-redux';
import { WebSocketContext } from '../../components/Context/websocket';
import axios from 'axios';
import Appconfig from '../../config/config'
import { useNavigate, useParams } from 'react-router-dom';

const MatchUpdates = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfos = useSelector((state) => state.eventData); // Use selector to access state
  const userFancyData = useSelector((state) => state.fancyData); // Use selector to access state
  const userbalance = useSelector((state) => state.userbal); // Use selector to access state
  const balance = userbalance?.userBalance?.balance;
  const userInfo = Helper(); // get login user details

  const websocket = useContext(WebSocketContext);
  const { event_id } = useParams();

  const [showPlaceBetPopup, setShowPlaceBetPopup] = useState(false);
  const [betType, setBetType] = useState(null);
  const [amount, setAmount] = useState(0); // State for amount in PlaceBet popup
  const [showIframe, setShowIframe] = useState(false); // State to manage iframe visibility
  const [showMatchStatus, setShowMatchStatus] = useState(true); // State for Match Status visibility
  const [showOpenBets, setShowOpenBets] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false); // State for success message popup
  const [betAmount, setBetAmount] = useState(false);
  const { matchTime } = useContext(TimeContext);
  const [betSource, setBetSource] = useState(""); // State to track if it's from 'Match Odds' or 'Bookmaker'
  const [teamName, setTeamName] = useState(""); // State for team name


  const [marketTypes, setMarketTypes] = useState([])
  const [blockMatchOdds, setBlockMatchOdds] = useState({})
  const [blockBookmaker, setBlockBookmaker] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [scoreUrl, setScoreUrl] = useState("");
  const [eventData, setEventData] = useState([])
  const [fancy, setFancy] = useState([])
  const [tvUrl, setTvUrl] = useState();
  const [eventType, setEventType] = useState('');
  const [eventName, setEventName] = useState('');
  const [openDate, setOpenDate] = useState('');
  
  const [matchOdds, setMatchOdds] = useState('')
  const [bookmaker, setBookmaker] = useState('')
  const [isEventLoading, setIsEventLoading] = useState(true)
  const [selectionIdNew, setSelectionIdNew] = useState('')
  const [is_fancy, setIs_fancy] = useState(false)
  const [fancyOddsData, setFancyOddsData] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const match_odds_bet_limit = 20;
  const [generalSetting, setGeneralSetting] = useState([]);
  const [fancyExposure, setFancyExposure] = useState([]);
  const [chips, setChips] = useState([]);
  const [showBetsSlip, setShowBetsSlip] = useState(false);
  const [selection_id, setSelection_id] = useState('')
  const [marketId, setMarketId] = useState('')
  const [type, setType] = useState('back')

  const [htmlId, setHtmlId] = useState('')
  const [size, setSize] = useState('')
  const [ProfitValue, setProfitValue] = useState(0);
  const [lossValue, setLossValue] = useState(0);
  const [StakeValue, setStakeValue] = useState(0);
  const [defaultStake, setDefaultStake] = useState(0)
  const [BetPlaceData, setBetPlaceData] = useState({
    event_id: "",
    market_id: "",
    is_back: "",
    price: "",
    is_fancy: "",
    selection_id: "",
    runner_name: "",
    PM_FANCY: false
  });

  function SetPosition(stake, priceVal, market_id, is_back, selection_id) {
    priceVal = parseFloat(priceVal)
    var MarketId = market_id;
    var MId = MarketId.replace('.', '');
    var selectionId = selection_id;
    var isback = is_back;
    stake = parseFloat(stake);
    let MatchMarketTypes = "";
    var runners = document.getElementsByClassName("position_" + MId);
    var tempRunners = "";
    for (var item of runners) {
      var selecid = item.getAttribute('data-id');
      var winloss = parseFloat(item.value);
      var curr = 0;
      if (selectionId == selecid) {
        if (isback) {
          if (MatchMarketTypes == 'M') {
            curr = winloss + ((priceVal * stake) / 100);
          } else {
            curr = winloss + ((priceVal * stake) - stake);
          }
        } else {
          if (MatchMarketTypes == 'M') {
            curr = winloss + (-1 * parseFloat((priceVal * stake) / 100));
          } else {
            curr = winloss + (-1 * parseFloat((priceVal * stake) - stake));
          }
        }
      } else {
        if (isback == 1) {
          curr = winloss + (-1 * (stake));
        } else {
          curr = winloss + stake;
        }
      }
      var currV = curr;
      if (document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId)) {
        document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId).setAttribute('data-value', winloss.toFixed(2))
        document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId).innerHTML = Math.abs(winloss.toFixed(2));
      }
      if (document.getElementById(selecid + "_maxprofit_list_loss_runner_next_" + MId)) {
        document.getElementById(selecid + "_maxprofit_list_loss_runner_next_" + MId).setAttribute('data-value', currV.toFixed(2))
        document.getElementById(selecid + "_maxprofit_list_loss_runner_next_" + MId).innerHTML = Math.abs(currV.toFixed(2));
      }
      if (document.getElementById(selecid + "_maxprofit_Mlist_loss_runner_next_" + MId)) {
        document.getElementById(selecid + "_maxprofit_Mlist_loss_runner_next_" + MId).setAttribute('data-value', currV.toFixed(2))
        document.getElementById(selecid + "_maxprofit_Mlist_loss_runner_next_" + MId).innerHTML = Math.abs(currV.toFixed(2));
      }
    }
  }
  const placeStakeValue = (stake) => {
    if (String(stake).startsWith("NaN")) {
      stake = String(stake).replace("NaN", "");
    }
    setStakeValue(parseFloat(stake));
    calc(stake, BetPlaceData.price, BetPlaceData.selection_id)
    SetPosition(stake, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id);
  };

  function calc(t_stake, priceVal, selection_id) {
    var isfancy = BetPlaceData.is_fancy;
    priceVal = parseFloat(priceVal);
    t_stake = parseFloat(t_stake);
    var isback = BetPlaceData.is_back
    if (!isfancy) {
      var pl = ((priceVal * t_stake) - t_stake);

      pl = parseFloat(pl.toFixed(2));
      if (isback) {
        setProfitValue(pl)
        setLossValue(t_stake)
      } else {
        setLossValue(pl)
        setProfitValue(t_stake)
      }
      // SetPosition(priceVal);
    } else {
      if (document.getElementById('fancy_lay_size_' + selection_id)) {
        var NoValume = parseInt(document.getElementById('fancy_lay_size_' + selection_id).innerHTML);
        var YesValume = parseInt(document.getElementById('fancy_back_size_' + selection_id).innerHTML);
        var inputno = parseFloat(document.getElementById(`fancy_lay_price_${selection_id}`).innerHTML);
        var inputyes = parseFloat(document.getElementById(`fancy_back_price_${selection_id}`).innerHTML);
      }


      pl = parseFloat(t_stake);

      if (inputno == inputyes) {
        if (isback) {
          setLossValue(pl.toFixed(2))
          setProfitValue((YesValume * pl / 100).toFixed(2))

        } else {
          setLossValue((NoValume * pl / 100).toFixed(2))
          setProfitValue(pl.toFixed(2))

        }
      } else {
        setLossValue(pl.toFixed(2))
        setProfitValue(pl.toFixed(2))
      }

    }
  }


  useEffect(() => {
    marketTypes?.map(market => {
      if (market?.market_name == "Match Odds") {
        getBlockMatchOdds(market.event_id, market.market_id);

      } else if (market?.market_name == "Bookmaker") {
        getBlockBookmaker(market.event_id, market.market_id);

      }
    })

  }, [marketTypes])

  function getBlockMatchOdds(event_id, market_id) {
    var data = JSON.stringify({
      user_id: Appconfig.superAdmin,
      event_id: event_id,
      market_id: market_id,

    });
    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}blockMarket/getBlockMatchOdds`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        try {
          // alert("Hello");
          // console.log('response.data', response.data)
          if (response.data.result) {

            setBlockMatchOdds(response.data.resultData[0])
          }
        } catch (e) {
          // postErrorToslack(e.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        // postErrorToslack(error.message);
      });
  }

  function getBlockBookmaker(event_id, market_id) {
    var data = JSON.stringify({
      user_id: Appconfig.superAdmin,
      event_id: event_id,
      market_id: market_id,

    });
    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}blockMarket/getBlockMatchOdds`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        try {
          // alert("Hello");
          // console.log('response.data', response.data)
          if (response.data.result) {

            setBlockBookmaker(response.data.resultData[0])
          }
        } catch (e) {
          // postErrorToslack(e.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        // postErrorToslack(error.message);
      });
  }


  useEffect(() => {
    if (userInfo) {
      dispatch(getUserBal({
        user_id: userInfo?._id,
      }))
    }
  }, [userInfo?._id])

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 300);
  }, [])

  useEffect(() => {
    getScoreUrl(event_id)
  }, [event_id])

  function getScoreUrl(eventId) {

    var data = JSON.stringify({
      event_id: eventId,
    });
    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}eventsDashboard/getScore`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        try {
          // alert("Hello");
          setScoreUrl(response.data.resultData.animation);
        } catch (e) {
          // postErrorToslack(e.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        // postErrorToslack(error.message);
      });
    // }
  }



  useEffect(() => {
    var data;
    if (userInfo) {
      data = {
        user_id: userInfo._id,
        event_id: event_id,
      };
    } else {
      data = {
        event_id: event_id,
      };
    }
    dispatch(getMatchData(data))
  }, [])

  useEffect(() => {
    if (userInfos.eventData[event_id]) {
      setEventData(userInfos.eventData[event_id])
      setMarketTypes(userInfos.eventData[event_id][0]?.marketTypes)
      setFancy(userInfos.eventData[event_id][0]?.fancy)


      setTvUrl(userInfos.eventData[event_id][0]?.tv_link);
      let eventType = userInfos.eventData[event_id][0];
      if (eventType?.event_type == 4) {
        setEventType('Cricket');
      } else if (eventType?.event_type == 2) {
        setEventType('Tennis');
      } else {
        setEventType('Soccer');
      }
      setEventName(eventType?.event_name)
      setOpenDate(eventType?.open_date)

      userInfos.eventData[event_id][0]?.marketTypes?.map((type) => {
        if (type.market_name == 'Bookmaker') {
          setBookmaker(type)
        }
        if (type.market_name == "Match Odds") {
          setMatchOdds(type)
        }

      })
      setIsEventLoading(false);

    }
  }, [userInfos.eventData[event_id]])

  useEffect(() => {
    dispatch(getMatchFancyData({
      event_id: event_id,
      user_id: userInfo ? userInfo._id : ''
    }))
  }, [])

  useEffect(() => {
    if (userFancyData.fancyData[event_id]) {
      fancyHtml(userFancyData?.fancyData[event_id])
    }

  }, [userFancyData.fancyData[event_id]])

  async function fancyHtml(fancys) {
    var superiors = ["270", "259", "185", "177", "30"];
    var matchId = event_id;
    if (matchId) {
      if (fancys) {
        let check_new_fields_added = false;

        const result = fancys.find(item => {
          console.log(`Checking item with selection_id: ${item.selection_id}, game_status: ${item.game_status}, selectionIdNew: ${String(selectionIdNew)}`);

          const gameStatusMatch = item.game_status === "SUSPENDED" || item.game_status === "Ball Running";
          const selectionIdMatch = String(item.selection_id) === String(selectionIdNew);

          console.log(`gameStatusMatch: ${gameStatusMatch}, selectionIdMatch: ${selectionIdMatch}`);

          return gameStatusMatch && selectionIdMatch;
        });

        // const result = fancys.find(item => item.game_status === "" && item.selection_id === selection_id);
        if (result) {
          setIs_fancy(false);
        }

        setFancyOddsData(fancys)

      }
    }
  }


  useEffect(() => {
    if (websocket) {
      websocket.onopen = () => {
        console.log('Websocket is open ', new Date());
        setIsReady(true)
      };

      if (websocket.readyState) {

        console.log('WESOCKET JOIN REQUEST SENT', {
          "action": "JOIN",
          "data": {
            "username": "suthard444",
            "event_id": event_id
          }
        });

        websocket.send(JSON.stringify(
          {
            "action": "JOIN",
            "data": {
              "username": "suthard444",
              "event_id": event_id
            }
          }))
      }


    }
  }, [websocket, event_id, isReady]);

  useEffect(() => {
    if (websocket) {
      websocket.onmessage = (evt) => {
        const data = JSON.parse(evt.data);
        if (data.action == "MARKET_UPDATE") {
          // console.log("MARKET SOCKET RESPONDING");
          let market = data.data;
          console.log('market', market);
          if (market) {
            if (market.market_types.length > 0) {
              market.market_types.map((market_type, index) => {
                market_type.hasOwnProperty("runners") && market_type.runners.map((runner, index) => {
                  // if (market_type.status == 'OPEN') {
                  // } else {
                  // }


                  // if (runner.market_id == '4.1680554980-BM') {
                  // }
                  if (checkHighestMarketPrice(runner, market_type.market_name)) {
                    if (runner.status == 'SUSPENDED' || market_type.status == "CLOSED") {
                      const selecid = runner.selection_id
                      const suspendedDiv = document.querySelector(`.suspended_${runner.market_id.replace('.', '')}_${selecid}`);
                      const suspendedMDiv = document.querySelector(`.Msuspended_${runner.market_id.replace('.', '')}_${selecid}`);
                      const suspendedMSOCCERDiv = document.querySelector(`.m-soccer-suspended_${runner.market_id.replace('.', '')}_${selecid}`);
                      const suspendedSOCCERDiv = document.querySelector(`.soccer-suspended_${runner.market_id.replace('.', '')}_${selecid}`);
                      if (suspendedDiv) {
                        suspendedDiv && suspendedDiv.classList.remove('hidden');
                      }
                      if (suspendedMDiv) {
                        suspendedMDiv && suspendedMDiv.classList.remove('hidden');
                      }
                      if (suspendedMSOCCERDiv) {
                        suspendedMSOCCERDiv && suspendedMSOCCERDiv.classList.remove('hidden');
                      }
                      if (suspendedSOCCERDiv) {
                        suspendedSOCCERDiv && suspendedSOCCERDiv.classList.remove('hidden');
                      }
                    }
                    else {
                      const selecid = runner.selection_id
                      const suspendedDiv = document.querySelector(`.suspended_${runner.market_id.replace('.', '')}_${selecid}`);
                      const suspendedMDiv = document.querySelector(`.Msuspended_${runner.market_id.replace('.', '')}_${selecid}`);
                      const suspendedMSOCCERDiv = document.querySelector(`.m-soccer-suspended_${runner.market_id.replace('.', '')}_${selecid}`);
                      const suspendedSOCCERDiv = document.querySelector(`.soccer-suspended_${runner.market_id.replace('.', '')}_${selecid}`);
                      if (suspendedDiv) {
                        suspendedDiv && suspendedDiv.classList.add('hidden');
                      }
                      if (suspendedMDiv) {
                        suspendedMDiv && suspendedMDiv.classList.add('hidden');
                      }
                      if (suspendedMSOCCERDiv) {
                        suspendedMSOCCERDiv && suspendedMSOCCERDiv.classList.add('hidden');
                      }
                      if (suspendedSOCCERDiv) {
                        suspendedSOCCERDiv && suspendedSOCCERDiv.classList.add('hidden');
                      }
                    }
                    const boxes = document.querySelectorAll('.market_runners_' + runner.market_id.replace('.', '') + '_' + runner.selection_id);
                    for (const box of boxes) {
                      box.classList.add('disabled');
                    }
                  }
                  else {
                    const boxes = document.querySelectorAll('.market_runners_' + runner.market_id.toString().replace('.', '') + '_' + runner.selection_id);
                    for (const box of boxes) {
                      box.classList.remove('disabled');
                    }
                  }

                  var match_odd_row = document.getElementsByClassName('table_row_' + runner.market_id.toString().replace('.', '') + '_' + runner.selection_id)[0];


                  if (runner.status == 'SUSPENDED' || runner.status == 'CLOSE' || runner.status == 'CLOSED') {


                    if (document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                      if (match_odd_row) {

                        match_odd_row.setAttribute('data-title', runner.status);
                        match_odd_row.parentElement.classList.add("suspended");
                      }
                      if (parseFloat(document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_price)) {
                      } else {
                      }
                      if (parseFloat(document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_price)) {
                      } else {
                      }
                    }
                    // mobile{
                    if (document.getElementById('MavailableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                      if (match_odd_row) {

                        match_odd_row.setAttribute('data-title', runner.status);
                        match_odd_row.parentElement.classList.add("suspended");
                      }
                      if (parseFloat(document.getElementById('MavailableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_price)) {
                      } else {
                      }
                      if (parseFloat(document.getElementById('MavailableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_price)) {
                      } else {
                      }
                    }
                    // }
                    if (document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                      if (match_odd_row) {

                        match_odd_row.setAttribute('data-title', runner.status);
                        match_odd_row.parentElement.classList.add("suspended");
                      }
                      if (parseFloat(document.getElementById('availableToBack2_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_2_price)) {
                      } else {
                      }
                      if (parseFloat(document.getElementById('availableToLay2_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_2_price)) {
                      } else {
                      }
                    }
                    if (document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                      if (match_odd_row) {

                        match_odd_row.setAttribute('data-title', runner.status);
                        match_odd_row.parentElement.classList.add("suspended");
                      }
                      if (parseFloat(document.getElementById('availableToBack3_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_3_price)) {
                      } else {
                      }
                      if (parseFloat(document.getElementById('availableToLay3_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_3_price)) {
                      } else {
                      }
                    }
                  } else {
                    if (document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                      if (match_odd_row) {
                        match_odd_row.parentElement.setAttribute('data-title', "");
                        match_odd_row.parentElement.classList.remove("suspended");
                      }
                      if (parseFloat(document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_price)) {
                        document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_price);
                      } else {
                        document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_price);
                      }
                      if (parseFloat(document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_price)) {
                        document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_price);
                      } else {
                        document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_price);
                      }
                    }
                    // mobile{
                    if (document.getElementById('MavailableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                      if (match_odd_row) {
                        match_odd_row.parentElement.setAttribute('data-title', "");
                        match_odd_row.parentElement.classList.remove("suspended");
                      }
                      if (parseFloat(document.getElementById('MavailableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_price)) {
                        document.getElementById('MavailableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_price);
                      } else {
                        document.getElementById('MavailableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_price);
                      }
                      if (parseFloat(document.getElementById('MavailableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_price)) {
                        document.getElementById('MavailableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_price);
                      } else {
                        document.getElementById('MavailableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_price);
                      }
                    }
                    // }
                    if (document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                      if (match_odd_row) {
                        match_odd_row.parentElement.setAttribute('data-title', "");
                        match_odd_row.parentElement.classList.remove("suspended");
                      }
                      if (parseFloat(document.getElementById('availableToBack2_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_2_price)) {
                        document.getElementById('availableToBack2_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_2_price);
                      } else {
                        document.getElementById('availableToBack2_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_2_price);
                      }
                      if (parseFloat(document.getElementById('availableToLay2_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_2_price)) {
                        document.getElementById('availableToLay2_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_2_price);
                      } else {
                        document.getElementById('availableToLay2_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_2_price);
                      }
                    }
                    if (document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                      if (match_odd_row) {
                        match_odd_row.parentElement.setAttribute('data-title', "");
                        match_odd_row.parentElement.classList.remove("suspended");
                      }
                      if (parseFloat(document.getElementById('availableToBack3_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_3_price)) {
                        document.getElementById('availableToBack3_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_3_price);
                      } else {
                        document.getElementById('availableToBack3_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_3_price);
                      }
                      if (parseFloat(document.getElementById('availableToLay3_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_3_price)) {
                        document.getElementById('availableToLay3_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_3_price);
                      } else {
                        document.getElementById('availableToLay3_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_3_price);
                      }
                    }


                  }
                  /************************Size */


                  if (document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                    document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                    document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                    if (parseFloat(document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_size)) {
                      document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add("spark");
                      setTimeout(function () {
                        if (document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id))
                          document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                      },
                        700);
                      document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_size);
                    } else {
                      document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_size);
                    }
                    if (parseFloat(document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_size)) {
                      document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add("spark");
                      document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_size);
                      setTimeout(function () {
                        if (document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id))
                          document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                      }
                        , 700);
                    } else {
                      document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_size);
                    }
                  }
                  // mobile
                  if (document.getElementById('MavailableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                    document.getElementById('MavailableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                    document.getElementById('MavailableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                    if (parseFloat(document.getElementById('MavailableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_size)) {
                      document.getElementById('MavailableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add("spark");
                      setTimeout(function () {
                        if (document.getElementById('MavailableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id))
                          document.getElementById('MavailableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                      },
                        700);
                      document.getElementById('MavailableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_size);
                    } else {
                      document.getElementById('MavailableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_size);
                    }
                    if (parseFloat(document.getElementById('MavailableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_size)) {
                      document.getElementById('MavailableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add("spark");
                      document.getElementById('MavailableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_size);
                      setTimeout(function () {
                        if (document.getElementById('MavailableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id))
                          document.getElementById('MavailableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                      }
                        , 700);
                    } else {
                      document.getElementById('MavailableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_size);
                    }
                  }
                  // }
                  if (document.getElementById('availableToBack2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                    document.getElementById('availableToBack2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                    document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                    if (parseFloat(document.getElementById('availableToBack2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_2_size)) {
                      document.getElementById('availableToBack2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add("spark");
                      setTimeout(function () {
                        if (document.getElementById('availableToBack2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id))
                          document.getElementById('availableToBack2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                      },
                        700);
                      document.getElementById('availableToBack2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_2_size);
                    } else {
                      document.getElementById('availableToBack2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_2_size);
                    }
                    if (parseFloat(document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_2_size)) {
                      document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add("spark");
                      document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_2_size);
                      setTimeout(function () {
                        if (document.getElementById('availableToBack2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id))
                          document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                      }
                        , 700);
                    } else {
                      document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_2_size);
                    }
                  }
                  if (document.getElementById('availableToBack3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                    document.getElementById('availableToBack3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                    document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                    if (parseFloat(document.getElementById('availableToBack3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_3_size)) {
                      document.getElementById('availableToBack3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add("spark");
                      setTimeout(function () {
                        if (document.getElementById('availableToBack3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id))
                          document.getElementById('availableToBack3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                      },
                        700);
                      document.getElementById('availableToBack3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_3_size);
                    } else {
                      document.getElementById('availableToBack3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_3_size);
                    }
                    if (parseFloat(document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_3_size)) {
                      document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add("spark");
                      document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_3_size);
                      setTimeout(function () {
                        if (document.getElementById('availableToBack3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id))
                          document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                      }
                        , 700);
                    } else {
                      document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_3_size);
                    }
                  }
                });
              });
            }
          }

        }
        // console.log('data111', data);

        if (data.action == "FANCY_UPDATE") {
          let market = data.data;
          // console.log('market', market);
          console.log("FANCY SOCKET RESPONDING");
          if (market) {
            if (market?.fancy_data.length) {
              fancyHtml(market?.fancy_data)
            }

            // fancyHtml(market)
          }
        }


      };
    }
  }, [websocket, event_id]);


  const checkHighestMarketPrice = (runner, market_name) => {
    let price_exceed_by_limit = false;
    // await Promise.all(
    // 1
    if (runner.market_id.toString().includes('BM')) {

      if (runner.back_1_price > 0) {
        if (parseFloat((runner.back_1_price / 100) + 1) > match_odds_bet_limit) {
          price_exceed_by_limit = true;
        }

      }

      if (runner.lay_1_price > 0) {
        if (parseFloat((runner.lay_1_price / 100) + 1) > match_odds_bet_limit) {
          price_exceed_by_limit = true;
        }

      }



      if (runner.back_1_price <= 0) {
        price_exceed_by_limit = true;
      }


      if (runner.lay_1_price <= 0) {
      }




    }
    else if (market_name == 'Bookmaker') {

      if (runner.back_1_price > 0) {
        if (parseFloat((runner.back_1_price / 100) + 1) > match_odds_bet_limit) {
          price_exceed_by_limit = true;
        }

      }

      if (runner.lay_1_price > 0) {
        if (parseFloat((runner.lay_1_price / 100) + 1) > match_odds_bet_limit) {
          price_exceed_by_limit = true;
        }

      }


      if (runner.back_1_price <= 0) {
        price_exceed_by_limit = true;
      }


      if (runner.lay_1_price <= 0) {
      }


    }
    else {
      if (parseFloat(runner.back_1_price) > match_odds_bet_limit || parseFloat(runner.lay_1_price) > match_odds_bet_limit) {
        price_exceed_by_limit = true;
      }


      if (parseFloat(runner.back_1_price) <= 0 && parseFloat(runner.lay_1_price) <= 0) {
        price_exceed_by_limit = true;
      }
    }


    // 2
    if (runner.market_id.toString().includes('BM')) {

      if (runner.back_2_price > 0) {
        if (parseFloat((runner.back_2_price / 100) + 1) > match_odds_bet_limit) {
          price_exceed_by_limit = true;
        }

      }

      if (runner.lay_2_price > 0) {
        if (parseFloat((runner.lay_2_price / 100) + 1) > match_odds_bet_limit) {
          price_exceed_by_limit = true;
        }

      }


      if (runner.back_2_price <= 0) {
        price_exceed_by_limit = true;
      }


      if (runner.lay_2_price <= 0) {
      }

    }
    else if (market_name == 'Bookmaker') {

      if (runner.back_2_price > 0) {
        if (parseFloat((runner.back_2_price / 100) + 1) > match_odds_bet_limit) {
          price_exceed_by_limit = true;
        }

      }

      if (runner.lay_2_price > 0) {
        if (parseFloat((runner.lay_2_price / 100) + 1) > match_odds_bet_limit) {
          price_exceed_by_limit = true;
        }

      }

      if (runner.back_2_price <= 0) {
        price_exceed_by_limit = true;
      }


      if (runner.lay_2_price <= 0) {
      }

    }
    else {
      if (parseFloat(runner.back_2_price) > match_odds_bet_limit || parseFloat(runner.lay_2_price) > match_odds_bet_limit) {
        price_exceed_by_limit = true;
      }


      if (parseFloat(runner.back_2_price) <= 0 && parseFloat(runner.lay_2_price) <= 0) {
        price_exceed_by_limit = true;
      }
    }


    // 3
    if (runner.market_id.toString().includes('BM')) {

      if (runner.back_3_price > 0) {
        if (parseFloat((runner.back_3_price / 100) + 1) > match_odds_bet_limit) {
          price_exceed_by_limit = true;
        }

      }

      if (runner.lay_3_price > 0) {
        if (parseFloat((runner.lay_3_price / 100) + 1) > match_odds_bet_limit) {
          price_exceed_by_limit = true;
        }

      }

      if (runner.back_3_price <= 0) {
        price_exceed_by_limit = true;
      }


      if (runner.lay_3_price <= 0) {
      }



    }
    else if (market_name == 'Bookmaker') {

      if (runner.back_3_price > 0) {
        if (parseFloat((runner.back_3_price / 100) + 1) > match_odds_bet_limit) {
          price_exceed_by_limit = true;
        }

      }

      if (runner.lay_3_price > 0) {
        if (parseFloat((runner.lay_3_price / 100) + 1) > match_odds_bet_limit) {
          price_exceed_by_limit = true;
        }

      }


      if (runner.back_3_price <= 0) {
        price_exceed_by_limit = true;
      }


      if (runner.lay_3_price <= 0) {
      }



    }
    else {
      if (parseFloat(runner.back_3_price) > match_odds_bet_limit || parseFloat(runner.lay_3_price) > match_odds_bet_limit) {
        price_exceed_by_limit = true;
      }


      if (parseFloat(runner.back_3_price) <= 0 && parseFloat(runner.lay_3_price) <= 0) {
        price_exceed_by_limit = true;
      }
    }

    // )


    return price_exceed_by_limit;
  }


  useEffect(() => {
    if (userInfo) {
      getGeneralSetting();
      getFancysExposure();
      getChips();
    }
  }, [event_id])

  function getGeneralSetting() {
    var data = JSON.stringify({
      user_id: userInfo?._id,
      event_id: event_id,

    });
    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}matchGeneralSetting/getAllGeneralSetting`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        try {
          // alert("Hello");
          // console.log('response.data', response.data)
          if (response.data.result) {

            setGeneralSetting(response.data.resultData)
          }
        } catch (e) {
          // postErrorToslack(e.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        // postErrorToslack(error.message);
      });
  }

  function getFancysExposure() {
    if (userInfo) {
      var data = JSON.stringify({
        event_id: event_id,
        user_id: userInfo._id,
      });
      var config = {
        method: "post",
        url: `${Appconfig.apiUrl}betting/getFancysExposure`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          try {
            if (response.data.resultData.length > 0) {
              setFancyExposure(response.data.resultData);
            }
          } catch (e) {
            console.log(e)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    else {
      navigate('/login')
    }
  }

  function getChips() {
    if (userInfo) {
      var data = JSON.stringify({
        user_id: userInfo._id,
      });
      var config = {
        method: "post",
        url: `${Appconfig.apiUrl}chips/getChips`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          try {
            if (response.status) {
              setChips(response.data)
            }
          } catch (e) {
            console.log(e)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    else {
      navigate('/login')
    }
  }

  const handleOpenBetSlip = (
    event_id,
    market_id,
    is_back,
    size,
    is_fancy,
    selection_id,
    runner_name,
    htmlId,
    PM_FANCY = null,
    market_name
  ) => {
    setShowBetsSlip(true);
    setSelection_id(selection_id)
    setMarketId(market_id)
    setType(is_back ? 'back' : 'lay')
    setHtmlId(htmlId)
    setSize(size)
    setIs_fancy(is_fancy)

    if (selection_id) {
      setSelectionIdNew(selection_id)
    }
    // console.log({
    //     'event_id': event_id,
    //     'market_id': market_id,
    //     'is_back': is_back,
    //     'size': document.getElementById(size)?.innerHTML,
    //     'is_fancy': is_fancy,
    //     'selection_id': selection_id,
    //     'runner_name': runner_name,
    //     'new_price': document.getElementById(htmlId)?.innerHTML,
    //     'PM_FANCY': PM_FANCY,
    //     'market_name': market_name,
    // })

    // console.log('yes ')

    let selectElements = document.getElementsByClassName('market_runners_' + market_id.replace('.', '') + '_' + selection_id);
    // if (selectElements.length > 0) {
    //     let check_class_exists = document.getElementsByClassName('market_runners_' + market_id.replace('.', '') + '_' + selection_id)[0].classList.contains('disabled');
    //     if (check_class_exists) {
    //         return false;
    //     }

    // }


    setProfitValue(0);
    setStakeValue(0)
    var priceNew = document.getElementById(htmlId).innerHTML;
    var lastFive = market_id.substr(market_id.length - 3);
    if (lastFive == "-BM") {
      priceNew = ((priceNew / 100) + 1).toFixed(2);
    }
    else {
      if (market_name == "Bookmaker") {
        priceNew = ((priceNew / 100) + 1).toFixed(2);
      }
    }
    // console.log('priceNew:', priceNew)
    setBetPlaceData({
      event_id: event_id,
      market_id: market_id,
      is_back: is_back ? 1 : 0,
      price: priceNew,
      is_fancy: is_fancy,
      selection_id: selection_id,
      runner_name: runner_name,
      PM_FANCY: PM_FANCY
    });
    setTimeout(function () {
      SetPosition(0, priceNew, market_id, is_back, selection_id)
      calc(0, priceNew, selection_id)
      placeStakeValue(defaultStake);
    }, 800);
  }

  function formatPrice(price) {
    if (price < 1000) {
      return price;
    }
    const suffixes = ["", "k", "M", "B"];
    const tier = Math.floor(Math.log10(price) / 3);
    const suffix = suffixes[tier];
    const scaledPrice = (price / Math.pow(10, tier * 3));
    return `${scaledPrice}${suffix}`;
  }

  const handlePlaceBackClick = (value, team, source) => {
    setBetType("back");
    setAmount(value);
    setTeamName(team);
    setBetSource(source); // Set the source (Match Odds or Bookmaker)
    setShowPlaceBetPopup(true);
  };

  const handlePlaceLayClick = (value, team, source) => {
    setBetType("lay");
    setAmount(value);
    setTeamName(team);
    setBetSource(source); // Set the source (Match Odds or Bookmaker)
    setShowPlaceBetPopup(true);
  };

  const handleClosePlaceBet = () => {
    setShowPlaceBetPopup(false);
  };

  const handleLiveClick = (e) => {
    e.preventDefault();
    setShowIframe((prevShowIframe) => !prevShowIframe);
  };

  const handlePriceClick = (price) => {
    setBetAmount(price);
  };

  const handleMarketClick = () => {
    setShowMatchStatus(true); // Show match status
    setShowOpenBets(false); // Hide open bets
    setShowIframe(false); // Hide iframe
  };

  const handleOpenBets = () => {
    setShowOpenBets(true); // Toggle Open Bets visibility
    setShowMatchStatus(false); // Hide match status when open bets are shown
  };

  const handlePlaceBet = () => {
    // Show the success popup
    setShowSuccessPopup(true);
    // Close the place bet popup
    setShowPlaceBetPopup(false);
  };

  return (
    <div>
      <Header />
      <div className="match-update-header">
        <div className="inside-content">
          <FaPlayCircle size={15} color="white" style={{ marginRight: 5, marginLeft: 5, marginTop: 3 }} />
          <span>{eventName ? eventName : ''}</span>
          <p>{openDate ? openDate : ''}</p>
        </div>
        <IoInformationCircleOutline size={15} color="white" style={{ marginRight: 5, marginLeft: 0, marginTop: 10 }} />
      </div>

      <div className="header-part-2">
        <a href="#" onClick={handleMarketClick}>MARKET</a>
        <a href="#" onClick={handleLiveClick}>
          LIVE <MdLiveTv size={15} color="white" style={{ marginBottom: 2, marginLeft: 2 }} />
        </a>
        <a href="#" onClick={handleOpenBets}>OPEN BETS</a>
      </div>

      {showIframe && (
        <iframe
          src="https://www.openstreetmap.org/export/embed.html?bbox=-0.004017949104309083%2C51.47612752641776%2C0.00030577182769775396%2C51.478569861898606&layer=mapnik"
          width="100%"
          height="200px"
          title="Live Match"
          style={{ border: "none", marginTop: "10px" }}
        />
      )}

      {showOpenBets && (
        <div className="open-bets-section">
          <h2>Open Bets</h2>
          <p>No open bets available.</p>
        </div>
      )}


      {/* matchOdds */}

      {eventData.length > 0 && (
        <div className="match-status">
          <div className="match-status-heading">
            <div className="match-odds-heading">
              <span> {matchOdds?.market_name}</span>
            </div>
            <div className="back-lay">
              <span className='black'>Back</span>
              <span className='lay'>Lay</span>
            </div>
          </div>
          <div className="match-details">
            {
              eventData.length > 0 && eventData.map((event, index) =>
                event.marketTypes && event.marketTypes.length > 0 && event.marketTypes.map((market, index) =>
                  market.marketRunners && market.marketRunners.length > 0 && market.market_name == "Match Odds" ?
                    market.marketRunners.map((runner, i) => (
                      <div className={`odd-even-clr bets-selections all-${event.status} table_row_${market.market_id.replace('.', '')}_${runner.selection_id} ${(runner.status === "suspended") ? "suspended" : ""} ${(checkHighestMarketPrice(runner, market.market_name)) ? "disabled" : ""} market_runners_${market.market_id.replace('.', '')}_${runner.selection_id}`} key={`Mofmarket${index}`}>
                        <input type="hidden" id="matchodds_id_preserve" value={market.market_id} />
                        <input type="hidden" className={`position_${market.market_id.replace('.', '')}`} data-id={runner.selection_id} value={Math.round(runner.exposure.toFixed(2))} />
                        <div className="team-name-box">
                          <span id="runnerName" className="team-name">{runner?.runner_name}</span>
                          <div className="team-number">
                            <span className={` team-back-number back-1 ${runner.status === "SUSPENDED" ? "suspended" : ""}`}
                              onClick={() =>
                                handleOpenBetSlip(
                                  event.event_id,
                                  market.market_id,
                                  true,
                                  `MavailableToBack1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`,
                                  false,
                                  runner.selection_id,
                                  runner.runner_name,
                                  `MavailableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`,
                                  false,
                                  market.market_name
                                )
                              }
                            >
                              <span id={`MavailableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`} className='backprice-match ' >{parseFloat(runner.back_1_price)}</span>
                              <span id={`MavailableToBack1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`} className='' style={{ fontSize: '10px' }}>{parseFloat(runner.back_1_size)}</span>
                            </span>
                            <span className={`team-lay-number lay-1 ${runner.status === "SUSPENDED" ? "suspended" : ""}`}
                              onClick={(e) => {
                                handleOpenBetSlip(
                                  event.event_id,
                                  market.market_id,
                                  false,
                                  `MavailableToLay1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`,
                                  false,
                                  runner.selection_id,
                                  runner.runner_name,
                                  `MavailableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`,
                                  false,
                                  market.market_name
                                )
                              }}
                            >
                              <span id={`MavailableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`} className='layprice-match'> {parseFloat(runner.lay_1_price)}</span>
                              <span id={`MavailableToLay1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`} className='' style={{ fontSize: '10px' }}>{parseFloat(runner.lay_1_size)}</span>
                            </span>
                          </div>
                        </div>
                      </div>

                    )) : ''))
            }
          </div>
        </div>
      )}


      {/* Toss */}
      {eventData.length > 0 && eventData.map((event, index) =>
        event.marketTypes && event.marketTypes.length > 0 && event.marketTypes.map((market, indexm) =>
          market.marketRunners && market.marketRunners.length > 0 && market.market_name == 'Toss' && (
            <div className="match-status">
              <div className="match-status-heading-toss">
                <span>To Win The Toss</span>
              </div>
              <div className="match-details-toss">

                {
                  market.marketRunners.slice(0, 2).map((runner) => (
                    <div>
                      <div className="team-toss-box">
                        <span
                          className="team-toss"
                        >
                          {runner?.runner_name.split(' ').slice(0, 3).join(' ')}
                        </span>
                      </div>
                      <div className="toss-box-number">
                        <div className="toss-box"
                          onClick={(e) => {
                            handleOpenBetSlip(
                              event.event_id,
                              market.market_id,
                              true,
                              `availableTossBackSize_${market.market_id.replace('.', '')}_${runner.selection_id}`,
                              false,
                              runner.selection_id,
                              runner.runner_name,
                              `availableTossBackPrice_${market.market_id.replace('.', '')}_${runner.selection_id}`,
                              false,
                              market.market_name
                            )

                          }
                          }
                        >
                          <span id={`availableTossBackPrice_${market.market_id.replace('.', '')}_${runner.selection_id}`} className=''>{runner?.back_1_price}</span>
                          <span id={`availableTossBackSize_${market.market_id.replace('.', '')}_${runner.selection_id}`} className='' style={{ fontSize: '10px' }}>{runner?.back_1_size}</span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

            </div>
          )))}


      {/* bookmaker */}

      <div className="match-status">
        {eventData.length > 0 &&
          eventData.map(
            (event, index) =>
              event.marketTypes &&
              event.marketTypes.length > 0 &&
              event.marketTypes.map(
                (market, indexm) =>
                  market.marketRunners &&
                  market.marketRunners.length > 0 &&
                  market.market_name !== "Match Odds" &&
                  market.market_name !== "Toss" &&
                  event.event_type !== "1" && (
                    <div key={`market-${indexm}`} className="match-status">
                      <div className="match-status-heading">
                        <span>{market?.market_name}</span>
                        <div className="back-lay">
                          <span className="back">Back</span>
                          <span className="lay">Lay</span>
                        </div>
                      </div>
                      <div
                        className={`match-details ${blockBookmaker &&
                          blockBookmaker?.oddsType == "Suspended" &&
                          "suspended-match"
                          }`}
                      >
                        {market.marketRunners.map((runner, i) => (
                          <div key={`runner-${i}`} className="team-name-box">
                            <span id="runnerName" className="team-name">{runner.runner_name}</span>
                            <span className=''>
                              <span id={runner.selection_id + "_maxprofit_list_loss_runner_prev_" + market.market_id.replace('.', '')} className={`win market-exposure `} data-value={runner.exposure}>{Math.abs(runner.exposure.toFixed(2))}</span>
                              {showBetsSlip && (<>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M0 3h4v4H0V3zm4 7V0l6 5-6 5z" fill="#508E0D" fill-rule="evenodd" /></svg>
                                <span id={runner.selection_id + "_maxprofit_Mlist_loss_runner_next_" + market.market_id.replace('.', '')} className={`to-win market-exposure `} ></span>
                              </>)}
                            </span>
                            <div className={"team-number bets-selections all-" + event.status + " relative border-b-[1px] border-b-[#7e97a7] " + "market_runners_" + market.market_id.replace('.', '') + '_' + runner.selection_id}>
                              <input type="hidden" id="bookmaker_id_preserve" value={market.market_id} />
                              <input type="hidden" className={`position_${market.market_id.replace('.', '')}`} data-id={runner.selection_id} value={Math.round(runner.exposure.toFixed(2))} />
                              {/* <div id="back_1" className={`bookmaker-back-1 table_row_${market.market_id.replace('.', '')}_${runner.selection_id}  ${runner.status === "suspended" ? "suspended" : ""}`}> */}
                              <span
                                className={`team-back-number bookmaker-back-1 table_row_${market.market_id.replace('.', '')}_${runner.selection_id}  ${runner.status === "suspended" ? "suspended" : ""}`}
                                onClick={() =>
                                  handleOpenBetSlip(
                                    event.event_id,
                                    market.market_id,
                                    true,
                                    `MavailableToBack1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`,
                                    false,
                                    runner.selection_id,
                                    runner.runner_name,
                                    `MavailableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`,
                                    false,
                                    market.market_name
                                  )
                                }
                              >
                                <span id={`MavailableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`} className='back-box '>{formatPrice(runner.back_1_price)}</span>
                                <span id={`MavailableToBack1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`} className='bookmakerSize ' style={{ fontSize: '10px' }}>{formatPrice(runner.back_1_size)}</span>
                              </span>
                              {/* </div> */}
                              {/* <div> */}
                              <span
                                className="team-lay-number"
                                onClick={() =>
                                  handleOpenBetSlip(
                                    event.event_id,
                                    market.market_id,
                                    false,
                                    `MavailableToLay1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`,
                                    false,
                                    runner.selection_id,
                                    runner.runner_name,
                                    `MavailableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`,
                                    false,
                                    market.market_name
                                  )
                                }
                              >
                                <span id={`MavailableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`} className='lay-box '>{formatPrice(runner.lay_1_price)}</span>
                                <span id={`MavailableToLay1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`} className='bookmakerSize ' style={{ fontSize: '10px' }}>{formatPrice(runner.lay_1_size)}</span>
                              </span>
                              {/* </div> */}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
              )
          )}
      </div>


      {/* bookmaker only for soccer */}

      <div className="match-status">
        {eventData.length > 0 &&
          eventData.map(
            (event, index) =>
              event.marketTypes &&
              event.marketTypes.length > 0 &&
              event.marketTypes.map(
                (market, index) =>
                  market.marketRunners &&
                  market.marketRunners.length > 0 &&
                  event.event_type == "1" &&
                  market.market_name != "Match Odds" &&
                  (
                    <>
                      {
                        1 == 1 &&

                        (
                          <div key={`market-${index}`} className="match-status">
                            <div className="match-status-heading">
                              <span>{market?.market_name}</span>
                              <div className="back-lay">
                                <span className="back">Back</span>
                                <span className="lay">Lay</span>
                              </div>
                            </div>
                            <div>
                              {market.marketRunners.map((runner, i) => (
                                <div key={`runner-${i}`} className="team-name-box">
                                  <span id="runnerName" className="team-name">{runner.runner_name}</span>
                                  <span className=''>
                                    <span id={runner.selection_id + "_maxprofit_list_loss_runner_prev_" + market.market_id.replace('.', '')} className={`win market-exposure `} data-value={runner.exposure}>{Math.abs(runner.exposure.toFixed(2))}</span>
                                    {showBetsSlip && (<>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M0 3h4v4H0V3zm4 7V0l6 5-6 5z" fill="#508E0D" fill-rule="evenodd" /></svg>
                                      <span id={runner.selection_id + "_maxprofit_Mlist_loss_runner_next_" + market.market_id.replace('.', '')} className={`to-win market-exposure `} ></span>
                                    </>)}
                                  </span>
                                  <div className={"team-number bets-selections all-" + event.status + " relative border-b-[1px] border-b-[#7e97a7] " + "market_runners_" + market.market_id.replace('.', '') + '_' + runner.selection_id}>
                                    <input type="hidden" id="bookmaker_id_preserve" value={market.market_id} />
                                    <input type="hidden" className={`position_${market.market_id.replace('.', '')}`} data-id={runner.selection_id} value={Math.round(runner.exposure.toFixed(2))} />
                                    {/* <div id="back_1" className={`bookmaker-back-1 table_row_${market.market_id.replace('.', '')}_${runner.selection_id}  ${runner.status === "suspended" ? "suspended" : ""}`}> */}
                                    <span
                                      className={`team-back-number bookmaker-back-1 table_row_${market.market_id.replace('.', '')}_${runner.selection_id}  ${runner.status === "suspended" ? "suspended" : ""}`}
                                      onClick={() =>
                                        handleOpenBetSlip(
                                          event.event_id,
                                          market.market_id,
                                          true,
                                          `MavailableToBack1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`,
                                          false,
                                          runner.selection_id,
                                          runner.runner_name,
                                          `MavailableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`,
                                          false,
                                          market.market_name
                                        )
                                      }
                                    >
                                      <span id={`MavailableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`} className='back-box '>{formatPrice(runner.back_1_price)}</span>
                                      <span id={`MavailableToBack1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`} className='bookmakerSize '>{formatPrice(runner.back_1_size)}</span>
                                    </span>
                                    {/* </div> */}
                                    {/* <div> */}
                                    <span
                                      className="team-lay-number"
                                      onClick={(e) => {
                                        handleOpenBetSlip(
                                          event.event_id,
                                          market.market_id,
                                          false,
                                          `MavailableToLay1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`,
                                          false,
                                          runner.selection_id,
                                          runner.runner_name,
                                          `MavailableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`,
                                          false,
                                          market.market_name
                                        )
                                      }}
                                    >
                                      <span id={`MavailableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`} className='lay-box '>{formatPrice(runner.lay_1_price)}</span>
                                      <span id={`MavailableToLay1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`} className='bookmakerSize ' >{formatPrice(runner.lay_1_size)}</span>
                                    </span>
                                    {/* </div> */}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )
                      }
                    </>
                  )
              )
          )}
      </div>

      {/* fancy */}
      <div className="match-status">
        {
          fancyOddsData.length > 0 && (
            <div className="fancy-market">
              <div className="match-status-heading">
                <span>Fancy</span>
              </div>
              {
                fancyOddsData.map((fancy, index) => (
                  <div>
                    <input type="hidden" id="fancy_id_preserve" value={fancy.market_id} />

                    <div className="fancy-match-details">
                      <div className="team-name-box fancy-box">
                        <span className="team-name"> {fancy.runner_name}
                          {/* <span id="before" onClick={() => getFancyPosition(fancy.selection_id)} className={`ml-[15px] cursor-pointer win fancywin win_${fancy.match_id}_${fancy.selection_id.replace('.', '___')} text-[0.688rem]  !font-bold`}
                            data-value={
                              getFancyExposure(fancy.selection_id)
                            }>
                            {
                              checkFancyExposureExists(fancy.selection_id)
                                ? <>{Math.abs(getFancyExposure(fancy.selection_id))}</> : null
                            }

                          </span> */}
                        </span>
                        <div className="team-number">
                          <span
                            className="team-back-number"
                            onClick={(e) =>
                              fancy.lay_price1 > 0 &&
                              handleOpenBetSlip(
                                fancy.match_id,
                                "",
                                false,
                                `fancy_lay_size_${fancy.selection_id}`,
                                true,
                                fancy.selection_id,
                                fancy.runner_name,
                                `fancy_lay_price_${fancy.selection_id}`,
                                false,
                                ''
                              )
                            }
                          >
                            <span className="widthhundredfancy" id={`fancy_lay_price_${fancy.selection_id}`}>{parseFloat(fancy.lay_price1)}</span>
                            <span className='widthhundredfancy ' id={`fancy_lay_size_${fancy.selection_id}`} style={{fontSize:'10px'}}> {parseFloat(fancy.lay_size1)}  </span>
                          </span>
                          {/* <div>
                            {fancy.game_status != '' && (
                              <div className=''>
                                <span className=''> {fancy.game_status} </span>
                              </div>
                            )} */}
                            <span
                              className="team-lay-number"
                              onClick={(e) =>
                                fancy.back_price1 > 0 &&
                                handleOpenBetSlip(
                                  fancy.match_id,
                                  "",
                                  true,
                                  `fancy_back_size_${fancy.selection_id}`,
                                  true,
                                  fancy.selection_id,
                                  fancy.runner_name,
                                  `fancy_back_price_${fancy.selection_id}`,
                                  false,
                                  ''
                                )
                              }
                            >
                              <span className="widthhundredfancy" id={`fancy_back_price_${fancy.selection_id}`}>{parseFloat(fancy.back_price1)}</span>
                              <span className='widthhundredfancy ' id={`fancy_back_size_${fancy.selection_id}`} style={{fontSize:'10px'}}>{parseFloat(fancy.back_size1)}</span>
                            </span>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }

            </div>
          )}
      </div>



      {showPlaceBetPopup && (
        <div className="modal-overlay" onClick={handleClosePlaceBet}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside the modal content
          >
            <PlaceBet
              betType={betType}
              initialAmount={amount}
              onClose={handleClosePlaceBet}
              onPlaceBet={handlePlaceBet}
              teamName={teamName}   // Pass the teamName prop
              betSource={betSource} // Pass the betSource prop
            />
          </div>
        </div>
      )}


      {showSuccessPopup && (
        <PlaceBetSuccessfull
          message="Bet placed successfully!"
          onClose={() => setShowSuccessPopup(false)} // Close the success popup
        />
      )}




      <Empty />

      <Footer />
    </div>
  );
};

const PlaceBet = ({ betType, initialAmount = 0, onClose, onPlaceBet, teamName, betSource, matchOdds }) => {
  const [betAmount, setBetAmount] = useState(initialAmount); // Initialize betAmount with the initialAmount prop
  const [price, setPrice] = useState("0");
  // Update betAmount when initialAmount changes
  React.useEffect(() => {
    setBetAmount(initialAmount);
  }, [initialAmount]);

  const handleIncrease = () => {
    setPrice((prevAmount) => prevAmount + 1);
  };

  const handleDecrease = () => {
    setPrice((prevAmount) => (prevAmount > 0 ? prevAmount - 1 : 0));
  };

  const handlePriceClick = (price) => {
    setPrice(price);
  };

  const handlePlaceClick = () => {
    // Call the function to place the bet with the selected team and odds
    onPlaceBet({ teamName, betAmount, matchOdds, betType });
  };


  // Dynamic background based on betType
  const backgroundColor = betType === "back" ? "rgb(179, 209, 235)" : "#ecb7de";

  return (
    <div className="placebet-container" style={{ backgroundColor }}>
      <div className="placebet-header">
        <IoMdClose className="close-icon" onClick={onClose} color="black" style={{ marginTop: 10, marginRight: 10 }} />
      </div>
      <div className="placebet-description">
        <span>
          <p>{betSource}</p> {/* Display betSource */}
          <p>{betType === "back" ? "Back" : "Lay"}: {teamName}</p> {/* Display teamName */}
          {/* <p>Match Odds: {matchOdds}</p> Display match odds */}
        </span>
      </div>
      <div className="placebet-boxes">
        <div className="rate-box">
          {/* Rate/Odds input box */}
          <div className="inc-dec-button">
            <button id="decrease" className="btn">-</button>
            <input
              type="number"
              value={betAmount} // Display the match odds value in this input field
              readOnly // Make it read-only
            />
            <button id="increase" className="btn">+</button>
          </div>
          {/* Bet amount input box */}
          <div className="input-number-box">
            <button id="decrease" className="btn" onClick={handleDecrease}>
              -
            </button>
            <input
              type="number"
              value={price} // Display betAmount in this input field
              min="0"
              onChange={(e) => setBetAmount(parseFloat(e.target.value))} // Allow user to edit the amount
            />
            <button id="increase" className="btn" onClick={handleIncrease}>
              +
            </button>
          </div>
        </div>
      </div>
      {/* Price buttons */}
      <div className="price-boxes">
        {[500, 2000, 5000, 10000, 15000, 20000].map((price) => (
          <button key={price} className="price-box-1" onClick={() => handlePriceClick(price)}>
            {price}
          </button>
        ))}
      </div>
      {/* Action buttons */}
      <div className="action-buttons">
        <button className="cancel-btn" onClick={onClose}>
          Cancel
        </button>
        <button className="placebet-btn" onClick={handlePlaceClick}>
          Place Bet
        </button>
      </div>
    </div>
  );
};

export default MatchUpdates;
