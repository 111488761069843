import React from 'react';
import './Hero.css';
// import "../../../src/App.css";

const Hero = ({ text, speed }) => {
  const animationDuration = speed ? `${speed}s` : '50s';

  return (
    <div className="scrolling-text">
      <p
        className="scrolling-text-content"
        style={{ animationDuration }} 
      >
        {text}
      </p>
    </div>
  );
};

export default Hero;
