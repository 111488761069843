import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import './HorseRacingList.css';
import "../../../src/App.css";
import { BiSolidCricketBall, BiFootball } from "react-icons/bi";
import { FaDice, FaHorse, FaHorseHead, FaRegEye, FaRocket, FaTrophy, FaUser } from "react-icons/fa";
import Header from '../header/Header';
import Footer from '../Footer/Footer';

const HorseRacingList = () => {
    const [currentTime, setCurrentTime] = useState(new Date('2024-08-26T04:30'));
    const scrollRefs = useRef([]);
  
    const navigate = useNavigate();
  
    const initializeScrollRefs = (index) => {
      if (!scrollRefs.current[index]) {
        scrollRefs.current[index] = React.createRef();
      }
    };
  
    const handleScroll = (scrollingRef) => {
      scrollRefs.current.forEach(ref => {
        if (ref.current && ref !== scrollingRef) {
          ref.current.scrollLeft = scrollingRef.current.scrollLeft;
        }
      });
    };
  
    // Move handleMatchClick here, outside of useEffect
    const handleMatchClick = () => {
      navigate('/matchupdates'); // Navigate to MatchUpdates
    };
  
    useEffect(() => {
      scrollRefs.current.forEach((ref) => {
        if (ref.current) {
          ref.current.addEventListener('scroll', () => handleScroll(ref));
        }
      });
  
      return () => {
        scrollRefs.current.forEach((ref) => {
          if (ref.current) {
            ref.current.removeEventListener('scroll', () => handleScroll(ref));
          }
        });
      };
    }, []);
  
    return (
        <>
        <Header/>
            <div className='main cricket-list'>
        
                {/* Cricket Section */}
                <div className="cricket">
                <div className="header-box">
                    {/* <BiSolidCricketBall size={25} color="orange" style={{ marginRight: 5, marginLeft: 5, marginTop: 3 }} /> */}
                    <FaHorseHead size={30} color="black" style={{ marginRight: 5, marginLeft: 5, marginTop: 3 }}  />
                    <span>Horse Racing</span>
                </div>
                <div className="bet-numbers">
                    <div className="box"><span>1</span></div>
                    <div className="box"><span>X</span></div>
                    <div className="box"><span>2</span></div>
                </div>
                </div>


                {/* Event Boxes */}
                {[...Array(6)].map((_, i) => {
                initializeScrollRefs(i + 6);
        
                return (
                    <div className="event-box" key={i + 6}>
                    <div className="date-time-box">
                        <p>{i % 2 === 0 ? 'Tomorrow' : currentTime.toLocaleDateString()}</p>
                        <span className="date">{currentTime.toLocaleTimeString()}</span>
                    </div>
                    <div className="matches"  onClick={handleMatchClick}>

                        <div className='a'>Match {i + 1}</div>
                        <p>Team B</p>
                    </div>
                    <div
                        className="event-numbers"
                        ref={scrollRefs.current[i + 6]}
                        onScroll={() => handleScroll(scrollRefs.current[i + 6])}
                    >
                        <div className="box"><span>3.9</span></div>
                        <div className="box"><span>3.55</span></div>
                        <div className="box"><span>2.16</span></div>
                        <div className="box-pink"><span>3.95</span></div>
                        <div className="box-pink"><span>3.6</span></div>
                        <div className="box-pink"><span>2.18</span></div>
                    </div>
                    </div>
                );
                })}
            </div>
            <Footer/>
      </>
    );
}

export default HorseRacingList;
