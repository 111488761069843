import React, { useState } from 'react';
import './SignUp.css'; // Import the CSS file
// import "../../../src/App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { IoMdCloseCircle } from "react-icons/io";
import { GiCombinationLock } from "react-icons/gi";
import { FaMobileAlt, FaCaretDown, FaUser, FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons
import axios from "axios";
import apiBaseUrl from "../../config/config";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

const SignUp = (props) => {
  const navigate = useNavigate();
    const { signup } = useAuth();

  const [formData, setFormData] = useState({
    site_code: apiBaseUrl.sitecodes,
    user_name: "",
    password: "",
    user_type: "User",
    master_id: apiBaseUrl.superAdmin,
    refercode: "",
  });

  const [showPopup, setShowPopup] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle password visibility

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    if(confirmPassword !== formData.password){
      toast.error("Password Not Matched!");
      return false;
    }

    var config = {
      method: "post",
      url: `${apiBaseUrl.apiUrl}users/addUsers`,
      headers: {
        "Content-Type": "application/json",
      },

      data: JSON.stringify(formData),
    };
    axios(config)
      .then(function (response) {
        if (response.data.result) {
          toast.success("Registered successful!");

          // localStorage.setItem(
          //   "userdata",
          //   JSON.stringify(response.data.resultData)
          // );
          signup(response.data.resultData);          
          setTimeout(() => {
            props?.toggleSignUpPopup(false);
            props?.handleClose();
          }, 1000);

        } else {
          toast.error(response.data.resultMessage);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle between showing and hiding password
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword); // Toggle between showing and hiding password
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />

      {/* Login Button */}
      {/* <button type="button" className="btn btn-warning" onClick={togglePopup}>Sign Up</button> */}
      {/* <button className="pushable-signup submit-btn login-trigger " onClick={togglePopup}>
        <span className="front-signup">Sign Up</span> 
      </button> */}

      {/* Popup */}
      {props?.showSignupPopup && (
        <div className="login-popup-overlay" onClick={props?.toggleSignUpPopup}>
          <div className="login-popup-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-btn">
            <IoMdCloseCircle onClick={props?.toggleSignUpPopup} />
            </span>
            <img className='form-logo' src="../Images/logo.png" alt="" />
            <form onSubmit={handleSubmit}>
              <div className="phnumber-signup">
                <span className='country-code'>+91</span>
              <input type="number" name="user_name"  
                     value={formData.user_name}
                     onChange={(e) => handleInputChange(e)} 
                     placeholder="Enter Your 10 Digit Number" 
                     className='phnumber' required />
              <button className='getotp'>Get OTP</button>
              </div>
              <input type="number" name="number" placeholder="Enter OTP" className='otp-input' />

              <div className="signup-password">
                <GiCombinationLock size={30} color="#e9b02e" style={{ marginRight: 0, marginTop: 5, marginLeft: 5 }}/>
                <input
                  className='password-input'
                  type={showPassword ? 'text' : 'password'}  // Toggle between 'text' and 'password'
                  name="password"
                  placeholder="Password"
                  required
                  value={formData.password}
                  onChange={(e) => handleInputChange(e)}
                />
                <span className="password-toggle-signup" onClick={togglePasswordVisibility}>
                  {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                </span> {/* Eye icon to toggle password visibility */}
              </div>
              <div className="signup-password">
                <GiCombinationLock size={30} color="#e9b02e" style={{ marginRight: 0, marginTop: 5, marginLeft: 5 }}/>
                <input
                  className='password-input'
                  type={showConfirmPassword ? 'text' : 'password'}  // Toggle between 'text' and 'password'
                  name="confirmpass"
                  placeholder="Confirm Password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <span className="confirm-password-toggle-signup" onClick={toggleConfirmPasswordVisibility}>
                  {showConfirmPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                </span> {/* Eye icon to toggle password visibility */}
              </div>
                
              <input type="text"
                     name="refercode" 
                     placeholder="Referral Code (if any)"
                     value={formData.refercode}
                     onChange={(e) => handleInputChange(e)} />
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                <label class="form-check-label" for="flexCheckDefault">
                  Remember me
                </label>
              </div>
              <button type='submit' className="pushable submit-btn">
                <span className="front">
                  Register
                </span>
              </button>
              <span className='other-login'>Or register with</span>
            <span className="other-button">
              <button className='google'><img src="./Images/google.png" alt="" className='google-image'/>Google</button>
              <button className='whatsapp'><img src="./Images/whatsapp.png" alt="" className='whatsapp-image' />WhatsApp</button>
            </span>
            <span className='new-account'>Already have an account? Login</span> {/* Added onClick event */}
            </form>
          </div>
        </div>
      )}
    </>
  );
}; 

export default SignUp;
