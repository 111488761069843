import React, { useState, useRef, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { BiSolidCricketBall, BiFootball, BiTennisBall } from 'react-icons/bi';
import './UpcomingEvents.css';
// import "../../../src/App.css";
import { TimeContext } from '../TimeContext/TimeContext';
import { useSelector, useDispatch } from 'react-redux';
import { getAllEvents } from '../../redux/slice/event/eventSlice';
import { emptyUserInfo } from '../../redux/slice/userInfo/userInfoSlice';
import Helper from "../../helper";
import ScrollGames from '../ScrollGames/ScrollGames';
import TableRow from '../TableRow/index'


const UpcomingEvents = () => {
  const [currentTime, setCurrentTime] = useState(new Date('2024-08-26T04:30'));
  const scrollRefs = useRef([]); // Array of refs for each event box
  const { setMatchTime } = useContext(TimeContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userInfos = useSelector((state) => state.events); // Use selector to access state
  const [allEvents, setAllEvents] = useState([]);
  const [isEventLoading, setIsEventLoading] = useState(true)
  const [events, setEvents] = useState([]);
  const [eventType, setEventType] = useState(4)
  const userInfo = Helper();

  // console.log('userInfos',userInfos);

  useEffect(() => {
    dispatch(getAllEvents({
      user_id: userInfo ? userInfo._id : "",
    }));
  }, [])

  useEffect(() => {
    if (userInfos.events.length) {
      const newEvents = userInfos?.events[0]?.competitions[0]?.events || [];
      setIsEventLoading(false);
      if (newEvents.length > 0 && (allEvents.length == 0 || JSON.stringify(newEvents) !== JSON.stringify(allEvents))) {
        setAllEvents(newEvents);
      }
    }
  }, [userInfos.events.length])

  const initializeScrollRefs = (index) => {
    if (!scrollRefs.current[index]) {
      scrollRefs.current[index] = React.createRef(); // Initialize the ref for the event box
    }
  };

  const handleScroll = (scrollingRef) => {
    if (scrollingRef?.current) {
      const scrollLeftValue = scrollingRef.current.scrollLeft; // Get the scroll position of the scrolled box
      scrollRefs.current.forEach(ref => {
        if (ref.current && ref !== scrollingRef) {
          ref.current.scrollLeft = scrollLeftValue; // Update the scroll position for all other boxes
        }
      });
    }
  };

  const filteredEvents = useMemo(() => {
    if (allEvents.length > 0) {
      return allEvents.filter(event => event.event_type == eventType);
    }
    return [];
  }, [allEvents, eventType]);

  useEffect(() => {
    setEvents(filteredEvents);
  }, [filteredEvents]);

  const handleMatchClick = (time) => {
    setMatchTime(time);
    navigate('/matchupdates'); // Navigate to MatchUpdates
  };

  useEffect(() => {
    scrollRefs.current.forEach((ref) => {
      if (ref.current) {
        // Add scroll event listener to each event box
        ref.current.addEventListener('scroll', () => handleScroll(ref));
      }
    });

    // Clean up event listeners when component unmounts
    return () => {
      scrollRefs.current.forEach((ref) => {
        if (ref.current) {
          ref.current.removeEventListener('scroll', () => handleScroll(ref));
        }
      });
    };
  }, []);

  return (
    <>
      <ScrollGames setEventType={setEventType} />
      <div className='main'>
        <ul className='live'>
          <li className='live-li'>LIVE</li>
          <li>My Bets(0)</li>
        </ul>

        {/* Cricket Section */}
        {events?.length > 0 && (
          <div className="cricket">
            <div className="header-box">
              {
                eventType == "4" ?
                  <>
                    <BiSolidCricketBall size={25} color="orange" style={{ marginRight: 5, marginLeft: 5, marginTop: 3 }} />
                    <span>Cricket</span>
                  </>
                  : eventType == "2" ?
                    <>
                      <BiTennisBall size={25} color="orange" style={{ marginRight: 5, marginLeft: 5, marginTop: 3 }} />
                      <span>Tennis</span>
                    </>
                    : eventType == "1" ?
                      <>
                        <BiFootball size={25} color="orange" style={{ marginRight: 5, marginLeft: 5, marginTop: 3 }} />
                        <span>Football</span>
                      </>
                      :
                      <></>
              }

            </div>
            <div className="bet-numbers">
              <div className="box"><span>1</span></div>
              <div className="box"><span>X</span></div>
              <div className="box"><span>2</span></div>
            </div>
          </div>
        )}

        {events?.map((event, index) => (
          <TableRow i={index} row={event} />
        ))}
      </div>
    </>
  );
};

export default UpcomingEvents;
