import React from 'react';
import './MainFooter.css';
// import "../../../src/App.css";

function MainFooter() {
  return (
    <div className="main-footer">
      <div className="main-footer-content">
        <div className="gaming-png">
         <img src="./Images/main-footer.png" alt="" />
        </div>
        <div className="main-footer-paragraph">
          <p>daddyexch
            is a limited liability company incorporated under the laws of Curacao Players are requested not to contact any untrusted sources for
            daddyexch
            accounts as this is an online site and they can only register independently without any agents. Only deposit through the account details generated by the system or provided by our official support team.
          </p>
        </div>
      </div>
      <div className="main-footer-payment-section">
        <img src="./Images/main-footer-upi.svg" alt="" />
        <img src="./Images/main-footer-bank-transfer.svg" alt="" />
        <img src="./Images/main-footer-be-gamble.svg" alt="" />
        <img src="./Images/main-footer-gambling-commission.svg" alt="" />
        <img src="./Images/main-footer-corga.svg" alt="" />
        <img src="./Images/main-footer-mga.svg" alt="" />
      </div>
    </div>
  );
}

export default MainFooter;