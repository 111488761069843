import React from "react";
import "./AccountStatement.css";
// import "../../../src/App.css";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import ExposureHeader from "../ExposureHeader/ExposureHeader";
import Empty from "../EmptyDiv/Empty";
// import 'bootstrap/dist/css/bootstrap.min.css';

const AccountStatement = () => {
  return (
    <>
      <Header />
      {/* <ExposureHeader /> */}
      <div className="account-statment">
        <p className="open-para">
          <span>Account Statement</span>
        </p>

        <div className="option-flex">
          
        
            <select className="select-dropdown for-ac">
              <option>
                <button className="account-statement-button">All</button>
              </option>
              <option>
                <button className="account-statement-button">Deposit</button>
              </option>
              <option>
                <button className="account-statement-button">
                  Withdraw
                </button>
              </option>
            </select>
          
        

          <ul className="search">
            {/* <li>Search:</li> */}
            <li className="input">
              <input type="text" className="search-input" placeholder="search"/>
            </li>
          </ul>
        </div>

        
          
        <div className="form">
          <form>
            <div className="date-time">
              <input type="date" className="d-and-t"  />
              <input type="date" className="d-and-t" />
            </div>
            <div className="new-buttons">
            <input type="submit" className="btn-submit" />
            <input type="reset" className="btn-submit" />
            </div>
          </form>
        </div>

      </div>

      
        <div className="table-container">
          <table className="scrollable-table">
            <thead>
              <tr>
                <th>Sr.no</th>
                <th>Date</th>
                <th>Remark</th>
                <th>Deposit</th>
                <th>Withdraw</th>
                <th>From / To</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {/* Sample data for the table */}
              <tr>
                <td>1</td>
                <td>2024-09-15 13:52:48</td>
                <td>
                  <span className="table-match">
                    Deposit 100
                  </span>
                </td>
                <td className="credit">100</td>
                <td className="debit">0 0</td>
                <td>Admin - User1</td>
                <td>100</td>
              </tr>
              <tr>
              <td>2</td>
                <td>2024-09-15 13:52:48</td>
                <td>
                  <span className="table-match">
                    Deposit 100
                  </span>
                </td>
                <td className="credit">100</td>
                <td className="debit">0 0</td>
                <td>Admin - User1</td>
                <td>200</td>
              </tr>
              <tr>
              <td>3</td>
                <td>2024-09-15 13:52:48</td>
                <td>
                  <span className="table-match">
                    Deposit 100
                  </span>
                </td>
                <td className="credit">100</td>
                <td className="debit">0 0</td>
                <td>Admin - User1</td>
                <td>300</td>
              </tr>

              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
        <Empty />
      <Footer />
    </>
  );
};

export default AccountStatement;
