import React from 'react';
import './ReferAndEarn.css';
// import "../../../src/App.css";
import Header from '../header/Header';
import Empty from '../EmptyDiv/Empty';
import Footer from '../Footer/Footer';
import { GiCrownCoin } from "react-icons/gi";


const ReferAndEarn = () => {
  return (
    <div>
      <Header />
      <div className="container">
        <div className="header-refer">
            <h1>Refer your friends<br />and Earn</h1>
        </div>

        <div className="reward-section">
            <img src="../Images/gift-box.png" alt="Gift Icon" className="gift-icon" />
            <div className="coin-point">
              <GiCrownCoin size={30} color="#ff4d00" style={{ marginRight: 10, marginLeft: 10 }} /><h2>100</h2>
            </div>
            <span>LoyaltyPoints</span>
            <p>Your friend gets 100 TimesPoints on sign up and you get 100 TimesPoints too every time!</p>
        </div>

        <div className="referral-code">
            <p>Your referral code</p>
            <div className="code-box">
                <span>ABCDG123</span>
                <button className="copy-btn">Copy Code</button>
            </div>
            <p className="share-text">Share your Referral Code via</p>
        </div>

        <div className="social-buttons">
            <button className="btn telegram">Telegram</button>
            <button className="btn facebook">Facebook</button>
            <button className="btn whatsapp">WhatsApp</button>
        </div>

        <div className="faq-section">
            <h3>Frequently Asked Questions</h3>
            <div className="faq-item">
                <p>What is Refer and Earn Program? <span>+</span></p>
            </div>
            <div className="faq-item">
                <p>How it works? <span>+</span></p>
            </div>
            <div className="faq-item">
                <p>Where can I use these LoyaltyPoints? <span>+</span></p>
            </div>
        </div>
    </div>
      <Empty />
      <Footer />
    </div>
  );
}

export default ReferAndEarn;
