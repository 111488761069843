import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Import the CSS file
// import "../../../src/App.css";
import { IoMdCloseCircle } from "react-icons/io";
import { FaMobileAlt, FaCaretDown, FaUser, FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import apiBaseUrl from "../../config/config";
import { useAuth } from "../AuthContext";


const Login = (props) => {
  const navigate = useNavigate();
    const { login } = useAuth();
  
  const [formData, setFormData] = useState({
    user_name: "",
    password: "",
    site_code: apiBaseUrl.sitecodes,
    user_type: "User",
  });


  const [showPopup, setShowPopup] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false); // State for toggling dropdown
  const [inputType, setInputType] = useState('mobile'); // State for controlling which input type is selected
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    var config = {
      method: "post",
      url: `${apiBaseUrl.apiUrl}users/userAuthenticate`,
      headers: {
        "Content-Type": "application/json",
      },

      data: JSON.stringify(formData),
    };
    axios(config)
      .then(function (response) {
        if (response.data.result) {
          toast.success("Login successful!");
          // localStorage.setItem(
          //   "userdata",
          //   JSON.stringify(response.data.resultData)
          // );
          login(response.data.resultData); 

          setTimeout(() => {
            props?.togglePopup();
            props?.handleClose();
          }, 1000);
        } else {
          toast.error("Invalid Credentials");
        }
        formData.user_name = "";
        formData.password = "";
      })
      .catch(function (error) {
        console.log(error);
      });
  };



  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleInputTypeChange = (type) => {
    setInputType(type);
    setDropdownOpen(false); // Close dropdown after selection
    setUsername(''); // Reset username field when switching input types
  };

  // const handleLogin = (e) => {
  //   e.preventDefault();

  //   // Hardcoded credentials for testing
  //   const hardcodedUsername = 'user';
  //   const hardcodedPassword = '1234';

  //   // Check the credentials
  //   if (username === hardcodedUsername && password === hardcodedPassword) {
  //     // Store login status in localStorage
  //     localStorage.setItem('isAuthenticated', 'true');
  //     setError(''); // Clear any previous errors

  //     // Navigate to the main page after successful login
  //     navigate('/');  // Redirect to home page (or whichever route you prefer)
  //   } else {
  //     setError('Invalid username or password');  // Display an error message
  //   }
  // };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle between showing and hiding password
  };

  const handleCreateAccount = () => {
    navigate('/signup'); // Navigate to the signup page
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />

      {/* Login Button */}
      {/* <button className="pushable-login submit-btn login-trigger " onClick={togglePopup}>
        <span className="front-login">Login</span> 
      </button> */}

      {/* Popup */}
      {props?.showPopup && (
        <div className="login-popup-overlay" onClick={() => props?.togglePopup()}>
          <div className="login-popup-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-btn">
              <IoMdCloseCircle onClick={() => props?.togglePopup()} />
            </span>
            <img className='form-logo' src="../Images/logo.png" alt="" />
            <form className="login-form" onSubmit={handleSubmit}>
              {/* Dynamic label */}
              <label className="input-label-above">
                {inputType === 'mobile' ? 'Mobile Number' : 'User ID'}
              </label>

              {/* Username Section with Dropdown */}
              <div className="username">
                <span className="input-label">
                  {inputType === 'mobile' ? '+91' : 'ID'}
                </span>

                <input
                  className='login-input'
                  type="text"
                  name="user_name"
                  placeholder={inputType === 'mobile' ? 'Enter mobile number' : 'Enter user ID'}
                  required
                  value={formData.user_name}
                  onChange={(e) => handleInputChange(e)}
                />

                <span className="icon" onClick={toggleDropdown}>
                  <FaMobileAlt size={30} color="#e9b02e" style={{ marginRight: 0 }} /> <FaCaretDown size={20} color="#e9b02e" style={{ marginRight: 0 }} />
                </span> {/* Mobile icon */}

                {/* Dropdown Menu */}
                {dropdownOpen && (
                  <div className="dropdown-menu show">
                    <div onClick={() => handleInputTypeChange('mobile')}><FaMobileAlt size={20} color="#e9b02e" style={{ marginRight: 0 }} />Mobile Number</div>
                    <div onClick={() => handleInputTypeChange('userid')}><FaUser size={20} color="#e9b02e" style={{ marginRight: 0 }} />User ID</div>
                  </div>
                )}
              </div>

              <span className="password-label">
                Password
              </span>
              <div className="password">
                <input
                  className='password-input'
                  type={showPassword ? 'text' : 'password'}  // Toggle between 'text' and 'password'
                  name="password"
                  placeholder="Password"
                  required
                  value={formData.password}
                  onChange={(e) => handleInputChange(e)}
                />
                <span className="password-toggle" onClick={togglePasswordVisibility}>
                  {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                </span> {/* Eye icon to toggle password visibility */}
              </div>

              <button type="submit" className="pushable submit-btn">
                <span className="front">
                  Login
                </span>
              </button>
            </form>
            {error && <p className="error">{error}</p>}
            <span className='other-login'>Or login with</span>
            <span className="other-button">
              <button className='google'><img src="./Images/google.png" alt="" className='google-image' />Google</button>
              <button className='whatsapp'><img src="./Images/whatsapp.png" alt="" className='whatsapp-image' />WhatsApp</button>
            </span>
            <span className='new-account' onClick={handleCreateAccount}>New User? Create Account</span> {/* Added onClick event */}
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
