import React, { useState } from 'react';
import './Footer.css';
// import "../../../src/App.css";
import { BiCricketBall, BiGift, BiHome } from "react-icons/bi";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { Offcanvas } from 'react-bootstrap';
import AccountStatus from '../AccountStatus/AccountStatus';
import { useNavigate } from 'react-router-dom';
import LoginPopup from '../LoginPopup/LoginPopup';

const Footer = () => {
  // const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showCricketList, setShowCricketList] = useState(false);
  const [showProfitAndLoss, setShowProfitAndLoss] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showReferAndEarn, setshowReferAndEarn] = useState(false);
  const navigate = useNavigate();

  const handleProfile = () => {
    setShowProfile(true);
    navigate("/profile");
  };

  // const handleClose = () => {
  //   setShowOffcanvas(false);
  // };

  const handleClick = () => {
    navigate('/');
  };

  const handleCricketBallClick = () => {
    setShowLoginPopup(true); // Show the login popup
  };

  const handleLoginPopupClose = () => {
    setShowLoginPopup(false); // Hide the login popup
  };

  const handleCricketList = () => {
    setShowCricketList(true);
    navigate('/cricketlist');
  }
  const handleProfitAndLoss = () => {
    setShowProfitAndLoss(true);
    navigate('/profitandloss')
  }

  const handleReferAndEarn = () => {
    setshowReferAndEarn(true);
    navigate('/referandearn')
  }

  return (
    <>
      <nav className="navbar-footer navbar-expand-lg">
        <div className="container-fluid">
          <div className='icons'>
            {/* Trigger login popup on click */}
            <button className='footer-button' onClick={handleCricketList}>
              <BiCricketBall size={20} color="white" style={{ marginRight: 0 }} />
              <p>Sports</p>
            </button>
            
            <button className='footer-button' onClick={handleReferAndEarn} >
              <BiGift size={20} color="white" style={{ marginRight: 0 }} />
              <p>Refer & Earn</p>
            </button>
            <button className='footer-button' onClick={handleClick}>
              <BiHome size={20} color="white" style={{ marginRight: 0 }} />
              <p>Home</p>
            </button>
            <button className='footer-button' onClick={handleProfitAndLoss}>
              <MdOutlineLibraryBooks size={20} color="white" style={{ marginRight: 0 }} />
              <p>Profit & Loss</p>
            </button>
            <button className='footer-button' onClick={handleProfile} style={{ cursor: 'pointer' }}>
              <FaRegUser size={15} color="white" style={{ marginRight: 0 }} />
              <p>Profile</p>
            </button>
          </div>
        </div>
      </nav>

      {/* Direct Login Form Popup */}
      {showLoginPopup && <LoginPopup onClose={handleLoginPopupClose} />}

      {/* Fullscreen Offcanvas for Account Status */}
      {/* <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end" className="offcanvas-fullscreen">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Account Status</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AccountStatus handleClose={handleClose} />
        </Offcanvas.Body>
      </Offcanvas> */}
    </>
  );
};

export default Footer;
